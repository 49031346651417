import React, { Fragment, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Switch } from '@mui/material';
import {
  Image,
  Visibility,
  AdsClick,
  PersonSearch,
  AddCircleRounded,
  AccessTime,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import contestService from '../../services/contestService';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyInput from '../../components/MynyfyInput';
import { LuckyDrawStatus } from '../../utils/enums';
import theme from '../../utils/theme';
import MynyfyText from '../../components/MynyfyText';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import moment from 'moment';

const PAGE_LIMIT = 20;

export default function PublishAdsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [myAds, setMyAds] = useState(null);
  const [canInfinity, setInfinity] = useState(true);
  const [SearchPublishAds, setSearchPublishAds] = useState('');
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    let data = { limit: PAGE_LIMIT, isActive: checked };
    getMyAds(data);
  }, [checked]);

  const getMyAds = (data) => {
    contestService
      .getMyAds(data)
      .then((res) => {
        if (res.success) {
          if (res.data?.length < PAGE_LIMIT) setInfinity(false);
          adsSortHandler(res.data);
        } else {
          setMyAds([]);
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      })
      .catch((e) => setMyAds([]));
  };

  const adsSortHandler = (res) => {
    let data = _.cloneDeep(res);
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.status == LuckyDrawStatus.ACTIVE) {
        if (item.isDelete) item.status = 'DELETED';
        else if (item.endDate) {
          let days = expiryHandler(item.endDate);
          if (days <= 0) item.status = 'EXPIRED';
        }
      }
    }
    setMyAds(data);
  };

  const expiryHandler = (date) => {
    let presentDate = moment().format('DD/MM/YYYY');
    let endDate = moment(date).format('DD/MM/YYYY');
    let pDate = moment(presentDate, 'DD/MM/YYYY');
    let eDate = moment(endDate, 'DD/MM/YYYY');
    let result = eDate.diff(pDate, 'days', false);
    return result + 1;
  };

  const statusColorHandler = (status) => {
    let color = {};
    if (status == LuckyDrawStatus.PENDING) {
      color = '#ffc107';
    } else if (status == LuckyDrawStatus.NOT_APPROVED) {
      color = '#ff9800';
    } else if (status == LuckyDrawStatus.REJECTED || status == 'DELETED' || status == 'EXPIRED') {
      color = 'red';
    } else if (status == LuckyDrawStatus.COMPLETED) {
      color = theme.PRIMARY_BLUE;
    } else {
      color = 'green';
    }
    return color;
  };

  return (
    <Fragment>
      <MynyfyHeader
        title={'All Regular Ads List'}
        customElememt={() => (
          <div style={{ cursor: 'pointer' }} onClick={() => navigate('/PublishAds/Create')}>
            <AddCircleRounded color='primary' />
          </div>
        )}
      />
      {!myAds ? (
        <MynyfyLoader />
      ) : _.isEmpty(myAds) ? (
        <MynyfyEmptyMsg message={'No Regular Ads are posted'} />
      ) : (
        <div id='PublishAd'>
          <div className='rowSB' style={{ padding: 10 }}>
            <MynyfyInput
              fullWidth
              type={'text'}
              placeHolder={'Search Ads'}
              value={SearchPublishAds}
              onChange={(e) => setSearchPublishAds(e.target.value)}
            />

            <Switch checked={checked} onChange={() => setChecked(!checked)} />
          </div>
          <div>
            <Table sx={{ minWidth: 300 }} size='small'>
              <TableHead style={{ backgroundColor: theme.PRIMARY_BLUE }}>
                <TableRow>
                  <TableCell align='center'>
                    <Tooltip title='Ads' arrow placement='top'>
                      <Image className='titleIcon' />
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title='Total Views' arrow placement='top'>
                      <Visibility className='titleIcon' />
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title='Unique Views' arrow placement='top'>
                      <PersonSearch className='titleIcon' />
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title='Website Clicks' arrow placement='top'>
                      <AdsClick className='titleIcon' />
                    </Tooltip>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title='Status' arrow placement='top'>
                      <AccessTime className='titleIcon' />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myAds?.map((res, i) => (
                  <TableRow
                    key={i}
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/PublishAds/' + res._id)}>
                    <TableCell align='center'>
                      <img src={res.image} className='listImg' />
                    </TableCell>
                    <TableCell align='center'>
                      <MynyfyText title={res.totalViews || 0} />
                    </TableCell>
                    <TableCell align='center'>
                      <MynyfyText title={res.totalContestants || 0} />
                    </TableCell>
                    <TableCell align='center'>
                      <MynyfyText title={res.websiteClicks || 0} />
                    </TableCell>
                    <TableCell align='center'>
                      <MynyfyText
                        title={
                          res?.status == LuckyDrawStatus.NOT_APPROVED
                            ? 'Not Approved'
                            : res?.status?.toLowerCase()
                        }
                        color={statusColorHandler(res.status)}
                        style={{ textTransform: 'capitalize' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </Fragment>
  );
}
