import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as ClaimIcon } from '../../assets/svg/claimed.svg';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import MynyfySpan from '../../components/MynyfySpan';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import transactionService from '../../services/transactionService';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CallRounded,
  CancelRounded,
  CheckCircleRounded,
  CircleRounded,
  ExpandMoreRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import storeService from '../../services/storeService';
import promoService from '../../services/promoService';
import { fetchMynts } from '../../redux/actions/mynts.action';
import { PromoType } from '../../utils/enums';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyText from '../../components/MynyfyText';
import theme from '../../utils/theme';
import ReferComponent from '../../components/ReferComponent';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyClaimed from '../../components/MynyfyClaimed';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyAlert from '../../components/MynyfyAlert';
import { objectReplace } from '../../utils/CommonMethods';
import {
  fetchCoupons,
  fetchRewards,
  fetchSortedCoupons,
  fetchSortedRewards,
} from '../../redux/actions/promo.action';
import SelectStoreType from '../../components/SelectStoreType';
import moment from 'moment';

const PromoDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const isLoggedIn = user.isLoggedIn;
  const promos = useSelector((state) => state.promos);

  const [isTerms, setIsTerms] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [address, setAddress] = useState(null);
  const [details, setDetails] = useState(null);
  const [isRefer, setIsRefer] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [code, setCode] = useState(null);
  const [openStoreTypeModal, setOpenStoreTypeModal] = useState(false);

  useEffect(() => {
    let reqDetails = route?.state?.details;
    if (reqDetails) {
      initialCalls(reqDetails);
    } else if (route?.state?.promoId) {
      getPromoById(route?.state?.promoId);
    }
  }, []);

  const getPromoById = (promoId) => {
    promoService.getPromoById(promoId).then((res) => {
      if (res.success) {
        let clonedData = _.cloneDeep(res.data);
        clonedData.days = expiryHandler(clonedData.endDate);
        clonedData.isRedeemed = clonedData?.claimedBy?.includes(id);
        initialCalls(clonedData);
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const expiryHandler = (date) => {
    let presentDate = moment().format('DD/MM/YYYY');
    let endDate = moment(date).format('DD/MM/YYYY');
    let pDate = moment(presentDate, 'DD/MM/YYYY');
    let eDate = moment(endDate, 'DD/MM/YYYY');
    let result = eDate.diff(pDate, 'days', false);
    return result + 1;
  };

  const initialCalls = (reqDetails) => {
    setDetails(reqDetails);
    if (reqDetails?.type == 'REWARD' && reqDetails?.isCode && reqDetails?.claimedBy?.includes(id)) {
      getCouponCode(reqDetails?._id);
    }
    if (reqDetails?.organization) addressCall(reqDetails?.organization?._id);
  };

  const getCouponCode = (promoId, isCopy) => {
    promoService.getCouponCodeOfPromo(promoId).then((res) => {
      if (res.success) {
        setCode(res.data);
        if (isCopy) {
          navigator.clipboard.writeText(res.data);
          dispatch(snackbarInfo({ open: true, type: 'success', message: 'Copied Coupon Code' }));
        }
      }
    });
  };

  const addressCall = (orgId) => {
    storeService.getStoreAddress(orgId).then((res) => {
      if (res.success) {
        setAddress(res.data);
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  };

  const typeSwitchCase = (key) => {
    let type;
    switch (key) {
      case PromoType.COUPON:
        type = 'Coupons';
        break;
      case PromoType.REWARD:
        type = 'Rewards';
        break;
      case PromoType.DEAL:
        type = 'Deals';
        break;
      case PromoType.GROUP_DEAL:
        type = 'Group Deals';
        break;
      case PromoType.DAY_DEAL:
        type = 'Deal of the day';
        break;
      case PromoType.ADMIN_OFFERS:
        type = 'Vouchers';
        break;
    }
    return type;
  };

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  const singleTypeSwitchCase = (key) => {
    let type;
    switch (key) {
      case PromoType.COUPON:
        type = 'claim the Coupon';
        break;
      case PromoType.REWARD:
        type = 'get the Reward';
        break;
      case PromoType.DEAL:
        type = 'claim it';
        break;
      case PromoType.GROUP_DEAL:
        type = 'purchase the Deal';
        break;
      case PromoType.DAY_DEAL:
        type = 'purchase the Deal';
        break;
    }
    return type;
  };

  const getPoints = () => {
    transactionService.getPoints().then((res) => {
      if (res?.data?.points) {
        dispatch(fetchMynts(res?.data?.points));
      }
    });
  };

  const claimPromo = () => {
    setIsClicked(true);
    promoService
      .claimPromo(details._id)
      .then((res) => {
        if (res.success) {
          setIsClaimed(true);
          setIsRefer(false);
          if (details.code || code) {
            copyCode();
          } else {
            getCouponCode(details._id, true);
            dispatch(
              snackbarInfo({ open: true, type: 'warning', message: 'Getting your Coupon Code' })
            );
          }
          if (details.points) {
            getPoints();
          }
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
          setIsClicked(false);
          insufficientMyntsHandler(res.message);
        }
      })
      .catch((e) => setIsClicked(false));
  };

  const scanAndClaimBackHandler = (apiResponse) => {
    if (apiResponse.success) {
      setIsClaimed(true);
      setIsRefer(false);
    } else {
      insufficientMyntsHandler(apiResponse.message);
      dispatch(snackbarInfo({ open: true, type: 'error', message: apiResponse.message }));
    }
  };

  const insufficientMyntsHandler = (message) => {
    if (message?.toLowerCase().includes('Insufficient MYNTS'.toLowerCase())) {
      setIsRefer(true);
    }
  };

  const redeemPromoHandler = (from) => {
    if (details.claimedBy?.includes(id) || details.isRedeemed) {
      if (from == 'btn') {
        promoRedeemNavigation(false);
      } else {
        copyCode();
      }
    } else if (
      (details.days > 0 ? false : true) ||
      details.status == 'COMPLETED' ||
      details.status == 'REDEEMED'
    ) {
      dispatch(snackbarInfo({ open: true, type: 'warning', message: 'Reward is Expired' }));
    } else {
      setIsAlert(true);
    }
  };

  const promoRedeemNavigation = (fromClaimed) => {
    if (details.type == PromoType.DEAL && details.organization) {
      // TODO navigate to scan qr code screen
      // navigate('Scan And Claim', {
      //   details: details,
      //   onGoBack: scanAndClaimBackHandler,
      // });
    } else if (details.url) {
      copyCode();
      window.open(details.url);
    } else if (details.organization) {
      storeService.viewStore({ orgId: details.organization._id, buyerId: id });
      navigate('/Stores/' + details.organization.urlId, { replace: fromClaimed ? true : false });
    } else if (details.brand) {
      navigate('/Brands/' + details.brand?.urlId, { replace: fromClaimed ? true : false });
    } else {
      setOpenStoreTypeModal(true);
    }
  };

  const copyCode = () => {
    navigator.clipboard.writeText(details.code || code);
    dispatch(snackbarInfo({ open: true, type: 'success', message: 'Copied Coupon Code' }));
  };

  const updateClaimedStatus = () => {
    let actualData =
      promos?.[
        details?.type === PromoType.COUPON
          ? route?.state?.isRestaurant
            ? 'foodCoupons'
            : 'coupons'
          : 'rewards'
      ]?.actual;
    let sortedData =
      promos?.[
        details?.type === PromoType.COUPON
          ? route?.state?.isRestaurant
            ? 'foodCoupons'
            : 'coupons'
          : 'rewards'
      ]?.sorted;
    let action = details?.type === PromoType.COUPON ? fetchCoupons : fetchRewards;
    let sortedAction = details?.type === PromoType.COUPON ? fetchSortedCoupons : fetchSortedRewards;

    let filteredPromo = sortedData.find((e) => e._id === details._id);
    let actualPromo = actualData.find((e) => e._id === details._id);
    filteredPromo.isRedeemed = true;
    actualPromo.isRedeemed = true;
    if (filteredPromo.claimedBy) {
      filteredPromo.claimedBy.push(id);
      actualPromo.claimedBy.push(id);
    } else {
      filteredPromo.claimedBy = [id];
      actualPromo.claimedBy = [id];
    }
    let modActualData = objectReplace(actualData, actualPromo, '_id');
    let modSortedData = objectReplace(sortedData, filteredPromo, '_id');
    dispatch(action(modActualData));
    dispatch(sortedAction(modSortedData));
  };

  const storeNavigation = (key) => {
    navigate(key);
    setOpenStoreTypeModal(false);
  };

  return details ? (
    <Fragment>
      <div id='Promos' style={{ backgroundColor: '#f0f0f0' }}>
        <MynyfyHeader title={'Details'} />
        <div className='container'>
          <div style={{ position: 'relative' }}>
            {details.image?.url ? (
              <img src={details.image?.url} className='detailsImg' />
            ) : (
              <div className='detailsImg'>
                <div style={{ height: 'inherit' }}>
                  <div className='promoNoImgBody'>
                    <div style={{ padding: 10 }}>
                      <MynyfyText title={'Get'} h3 bold center />
                      <MynyfyText
                        title={theme.RUPEE + details.actualPrice + ' off'}
                        h1
                        bold
                        center
                        style={{ paddingTop: 2 }}
                      />
                      {details.minimumOrderValue ? (
                        <MynyfyText
                          title={'on a minimum order of ' + theme.RUPEE + details.minimumOrderValue}
                          style={{ paddingTop: 8 }}
                          h5
                          center
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* {details.claimedBy?.includes(id) ? <ClaimIcon className='claimIcon' /> : null} */}
          </div>
          <div className='rowSB'>
            <MynyfyText title={details.name} className='name' bold />
            {details.quantity ? (
              <MynyfyText
                title={`QTY: ${details.quantity} ${details.units || ''}`}
                className='qty'
              />
            ) : null}
          </div>
          <MynyfyText title={details.description} style={{ paddingTop: 10 }} />
        </div>

        {details.brand ? (
          <Fragment>
            <div
              className='container'
              style={{ padding: '13px 20px' }}
              onClick={() => navigate('/Brands/' + details.brand?.urlId)}>
              <div className='row'>
                {details.brand?.logo ? (
                  <img src={details.brand?.logo?.url} className='logo link' />
                ) : null}
                <MynyfyText title={details.brand?.brand} bold link style={{ margin: '0px 10px' }} />
                <MynyfyText title={'View Page'} link bold small className='brandBtn' />
              </div>
            </div>
          </Fragment>
        ) : null}

        <div className='container'>
          {
            // details.isRedeemed ? (
            //   <div className='rowSB'>
            //     <MynyfyText title={'You have Redeemed'} bold style={{ color: 'green' }} />
            //     <CheckCircleRounded fontSize='' color='success' style={{ marginRight: -1 }} />
            //   </div>
            // ) :
            details.days <= 0 ? (
              <div className='rowSB'>
                <MynyfyText title={'Expired'} bold style={{ color: 'red' }} />
                <CancelRounded fontSize='' color='error' />
              </div>
            ) : details.days > 0 && details.count - (details.claims || 0) > 0 ? (
              <div className='rowSB'>
                <MynyfyText title={'Expires in'} bold />
                <MynyfyText title={`${details.days} days`} style={{ color: 'red' }} />
              </div>
            ) : (
              <div className='rowSB'>
                <MynyfyText title={'You Missed it'} bold style={{ color: 'red' }} />
                <CancelRounded fontSize='' color='error' />
              </div>
            )
          }

          {/* {details.type !== PromoType.ADMIN_OFFERS ? (
            <Fragment>
              <div className='rowSB' style={{ marginTop: 10 }}>
                <MynyfyText title={`${typeSwitchCase(details.type)} available`} bold p />
                <MynyfyText title={details.count - (details.claims || 0)} p />
              </div>

              <div className='rowSB' style={{ marginTop: 10 }}>
                <MynyfyText title={`${typeSwitchCase(details.type)} claimed`} bold p />
                <MynyfyText title={details.claims || 0} p />
              </div>
            </Fragment>
          ) : null} */}

          {details.type !== PromoType.COUPON &&
          details.type !== PromoType.POINT_COUPON &&
          details.points ? (
            <div className='rowSB' style={{ marginTop: 10 }}>
              <MynyfyText title={'Mynts required'} bold p />
              <MynyfyText title={details.points} p />
            </div>
          ) : null}

          {details.minimumOrderValue ? (
            <div className='rowSB' style={{ marginTop: 10 }}>
              <MynyfyText title={'Minimum Order Value'} bold p />
              <MynyfyText title={details.minimumOrderValue} p />
            </div>
          ) : null}

          {details.actualPrice ? (
            <>
              <div className='dashedLine' />
              <div className='rowSB' style={{ marginBottom: 10 }}>
                <MynyfyText title={'Shop and Save'} bold />
                <MynyfyText title={`${theme.RUPEE}${details.actualPrice}`} />
              </div>
              {details.value ? (
                <div className='rowSB' style={{ marginBottom: 10 }}>
                  <MynyfyText title={'Saving'} bold />
                  <MynyfyText
                    title={`${theme.RUPEE}${details.actualPrice - details.value}`}
                    style={{ color: 'green' }}
                  />
                </div>
              ) : null}
            </>
          ) : null}

          {details.type === PromoType.DEAL ||
          details.type === PromoType.GROUP_DEAL ||
          details.type == PromoType.DAY_DEAL ? (
            <>
              {!details.actualPrice && details.value ? <div className='dashedLine' /> : null}
              {details.value ? (
                <div className='rowSB'>
                  <MynyfyText title={'To pay'} bold />
                  <MynyfyText
                    title={`${theme.RUPEE}${details.value}`}
                    style={{ color: 'orangered' }}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        {details.organization ? (
          <div className='container'>
            <div className='rowSB'>
              <div className='row'>
                <MynyfyText title={'Store Address'} bold p />
                <div
                  onClick={() => {
                    if (details.organization.contactNo) {
                      window.open('tel:' + details.organization.contactNo);
                    } else {
                      window.open('tel:' + details.organization.ownerId.profile.mobile);
                    }
                  }}>
                  <CallRounded
                    fontSize='small'
                    color='primary'
                    style={{ padding: '0 10px', margin: '0 10px' }}
                  />
                </div>
              </div>
              <MynyfyText
                title={'Visit Store'}
                bold
                small
                link
                style={{ padding: '5px 0px' }}
                onClick={() => {
                  if (isLoggedIn) {
                    storeService.viewStore({ orgId: details.organization._id, buyerId: id });
                  }
                  navigate('/Stores/' + details.organization.urlId);
                }}
              />
            </div>
            <MynyfyText
              title={details.organization.name}
              style={{ margin: '10px 0px', color: theme.DARK_BLUE }}
              bold
              h6
            />
            {address ? (
              <div>
                <MynyfySpan title={addressHandler(address.lane1)} />
                <MynyfySpan title={addressHandler(address.lane2)} />
                <MynyfySpan title={addressHandler(address.locality)} />
                <MynyfySpan title={addressHandler(address.landMark)} />
                <MynyfySpan title={addressHandler(address.city)} />
                <MynyfySpan title={addressHandler(address.state)} />
                <MynyfySpan title={address.pincode} />
              </div>
            ) : null}
          </div>
        ) : null}

        {/* {details.code || details.isCode ? (
          <div className='container' style={{ padding: '10px 20px' }}>
            <MynyfyText title={'Coupon Code'} bold p style={{ paddingBottom: 10 }} />
            <div className='rowSB couponContainer'>
              <MynyfyText
                title={details.claimedBy?.includes(id) ? details.code || code : 'XXXXXX'}
                style={{ paddingRight: 10 }}
                p
              />
              <div
                onClick={() => {
                  if (!isClicked) redeemPromoHandler('btn');
                }}
                className='copyContainer'>
                <MynyfyText
                  primary
                  small
                  bold
                  link
                  title={
                    details.claimedBy?.includes(id) || details.isRedeemed ? 'Shop' : 'Get Code'
                  }
                />
              </div>
            </div>
          </div>
        ) : null} */}

        {details.terms ? (
          <Fragment>
            <div className='container cursor' onClick={() => setIsTerms(!isTerms)}>
              <div className='rowSB'>
                <MynyfyText title={'Terms and Conditions'} bold />
                {isTerms ? (
                  <KeyboardArrowUpRounded fontSize='small' />
                ) : (
                  <ExpandMoreRounded fontSize='small' />
                )}
              </div>
            </div>

            {isTerms ? (
              <div className='container' style={{ marginTop: -20, paddingTop: 0 }}>
                {details.terms.split('$').map((res, i) => (
                  <div className='row' style={{ marginTop: 10 }} key={i}>
                    <CircleRounded className='dotIcon' />
                    <MynyfyText
                      title={res}
                      style={{ marginTop: 3, flexWrap: 'wrap', flexShrink: 1 }}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </Fragment>
        ) : null}

        {/* {details.url ? (
          <Fragment>
            <div 
              className="container"
              style={{ padding: '18px 0px' }}
              onClick={() => window.open(details.url)}
            >
              <div className='rowSB'>
                <MynyfyText title={"view Website"} bold p primary />
                <WebsiteIcon width={20} height={20} />
              </div>
            </div>
          </Fragment>
        ) : null} */}

        {isRefer ? <ReferComponent /> : null}

        <div style={{ padding: 10, textAlign: 'center' }}>
          <MynyfyButton
            label={'Shop Now'}
            className='statusBtn'
            onClick={() => promoRedeemNavigation()}
            disabled={isClicked}
            loading={isClicked}
          />
        </div>
      </div>
      <MynyfyClaimed
        title={
          details.type === PromoType.DEAL
            ? 'Congratulations! You have Successfully claimed the Deal'
            : details.isHomeDelivery
            ? 'Thank you for your Order, Seller will contact you shortly'
            : details.organization
            ? `Congratulations! Please visit the page to ${singleTypeSwitchCase(details.type)}`
            : details.type === PromoType.REWARD
            ? `Congratulations for claiming the Reward`
            : 'Congratulations! Please visit website to claim the Reward'
        }
        open={isClaimed}
        callback={() => {
          setIsClaimed(false);
          setIsClicked(false);
          updateClaimedStatus();
          if (isOrders) {
            setIsOrders(false);
            navigate('Orders');
          } else {
            promoRedeemNavigation(true);
          }
        }}
      />

      <MynyfyAlert
        open={isAlert}
        close={() => setIsAlert(false)}
        title={
          details.type === PromoType.COUPON
            ? 'Shop Now and Redeem your Coupon'
            : 'Are you sure you want to get the code?'
        }
        content={details.type === PromoType.COUPON ? '' : 'Mynts deducted will not be refunded.'}
        action1={{ label: 'No', handler: () => setIsAlert(false), color: 'black' }}
        action2={{
          label: 'Yes',
          handler: () => {
            setIsAlert(false);
            claimPromo();
          },
        }}
      />

      <SelectStoreType
        isVisible={openStoreTypeModal}
        closeModal={() => setOpenStoreTypeModal(false)}
        isRestaurant={route?.state?.isRestaurant}
      />
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default PromoDetails;
