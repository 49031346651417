import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyHeader from '../../components/MynyfyHeader';
import './stores.scss';
import { Card, CircularProgress, Drawer, IconButton } from '@mui/material';
import {
  FavoriteBorderRounded,
  FavoriteRounded,
  ContactPhoneOutlined,
  StyleOutlined,
  Menu,
  ChevronLeftRounded,
  AccountCircleOutlined,
  Person2Outlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import MynyfyTab from '../../components/MynyfyTab';
import {
  fetchFavOfflineStores,
  fetchFavStores,
  fetchOfflineStores,
  fetchStores,
} from '../../redux/actions/store.action';
import { likeStore, unlikeStore } from './StoresHelper';
import StoreAddress from './StoreAddress';
import storeService from '../../services/storeService';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import Promos from '../Promos';
import { OrderStatus, PromoType } from '../../utils/enums';
import SignInModal from '../Auth/SignInModal';
import Products from '../Products';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import promoService from '../../services/promoService';
import ProductCartHelper from '../Products/ProductCartHelper';
import { Carousel } from 'react-responsive-carousel';
import AllContests from '../Contest/AllContests';
import moment from 'moment';
import { storeTimingHandler } from '../../utils/CommonMethods';
import productOrderService from '../../services/productOrderService';
import Policies from './Policies';
import { ReactComponent as OrdersIcon } from '../../assets/svg/basket.svg';
import { ReactComponent as ContestsIcon } from '../../assets/svg/shop_and_win.svg';
import { ReactComponent as CouponsIcon } from '../../assets/svg/coupons.svg';
import { ReactComponent as PoliciesIcon } from '../../assets/svg/outlineRefund.svg';
import theme from '../../utils/theme';
import OrdersList from '../Orders/OrdersList';
import { fetchOrders, fetchOrdersHistory } from '../../redux/actions/order.action';

const StoreDetails = ({ site }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const urlParams = useParams();
  const [searchParams] = useSearchParams();

  const profile = useSelector((state) => state.user?.profile);
  const stores = useSelector((state) => state.stores);
  const promos = useSelector((state) => state.promos);
  const bag = useSelector((state) => state.bag);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const orders = useSelector((state) => state.orders);

  const [storeName, setStoreName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [isEcommerceActive, setEcommerceActive] = useState(false);
  const [isStoreOpened, setStoreOpened] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);

  const isFoodCategory = details?.ownerId?.categories?.find(
    (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
  );

  useEffect(() => {
    let urlId = site || urlParams?.store;
    let table = urlParams?.table;
    setStoreName(urlId || '');
    if (table) {
      productOrderService
        .getProductOrderIdByTable({ table, status: OrderStatus.ACTIVE })
        .then((res) => {
          if (res.success) {
            storeDetailsHandler(res.data);
          } else storeDetailsHandler();
        });
    } else storeDetailsHandler();
  }, []);

  const storeDetailsHandler = (order) => {
    let urlId = site || urlParams?.store;
    if (state?.storeDetails) {
      setDetails(state?.storeDetails);
      ecommerceStatus(state?.storeDetails?.ecommerceSubscription);
      let storeStatus = storeTimingHandler(state?.storeDetails);
      setStoreOpened(storeStatus);
      if (!_.isEmpty(order)) orderNavigation(order, state?.storeDetails);
    } else if (urlId) {
      storeService.getStores({ urlId: urlId }).then((res) => {
        if (res.success) {
          setDetails(res.data.orgList[0]);
          ecommerceStatus(res.data.orgList[0]?.ecommerceSubscription);
          let storeStatus = storeTimingHandler(res.data.orgList[0]);
          setStoreOpened(storeStatus);
          if (!_.isEmpty(order)) orderNavigation(order, res.data.orgList[0]);
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.data.message }));
        }
      });
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Products':
        return (
          <Products
            from={'seller'}
            sellerDetails={details}
            isEcommerceActive={isEcommerceActive}
            type={searchParams.get('type')}
            table={urlParams?.table}
          />
        );
      case 'Orders':
        return (
          <div>
            <MynyfyTab
              tabs={['Active', 'Closed']}
              tabComponent={[
                <OrdersList
                  type='ACTIVE'
                  data={orders?.active}
                  action={fetchOrders}
                  sellerId={details._id}
                />,
                <OrdersList
                  type='NONACTIVE'
                  data={orders?.history}
                  action={fetchOrdersHistory}
                  sellerId={details._id}
                />,
              ]}
            />
          </div>
        );

      case 'Contests':
        return (
          <MynyfyTab
            noBackBtn={true}
            tabs={['Active', 'Winners']}
            tabComponent={[
              <AllContests sellerDetails={details} status='active' from={'seller'} />,
              <AllContests
                sellerDetails={details}
                status='NOACTIVE'
                from={'seller'}
                isHistory={true}
              />,
            ]}
          />
        );
      case 'Coupons':
        return (
          <Promos
            type={[PromoType.COUPON, PromoType.POINT_COUPON]}
            isFilter={false}
            emptyListTitle={'Coupons'}
            emptyListMsg={isLoggedIn ? ['No Active coupons or offers'] : 'Please Login for Coupons'}
            emptyListMsgBold={isLoggedIn ? [1, 2] : null}
            promos={promos?.promos?.actual}
            sortedPromos={promos?.promos?.sorted}
            action={fetchPromos}
            sortedAction={fetchSortedPromos}
            sellerDetails={details}
            screen={'Coupons'}
            from={'seller'}
          />
        );
      case 'Contact Us':
        return <StoreAddress details={details} />;
      case 'Policies':
        return <Policies details={details} />;
      default:
        return null;
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const orderNavigation = (order, orgData) => {
    let table = urlParams?.table;
    navigate('/TableOrder/' + table, { state: { order, storeDetails: orgData } });
  };

  const ecommerceStatus = (subscription) => {
    if (subscription) {
      let subDays = moment(subscription).diff(moment(), 'days');
      if (subDays >= 0) {
        setEcommerceActive(true);
      } else {
        setEcommerceActive(false);
      }
    } else {
      setEcommerceActive(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        // do nothing
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Store',
            name: details.name,
            city: details.city,
            state: details.state,
            policies: details.policies,
            urlId: details.urlId,
            url: process.env.REACT_APP_WEB_HOST + '/Stores/' + details.urlId,
          })
        );
        if (isLoggedIn) getBuyerPromos(details._id);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (id) => {
    let body = {
      type: [PromoType.COUPON, PromoType.REWARD],
      organization: id,
      noOrg: true,
    };
    if (isLoggedIn) {
      body.buyer = profile._id;
    }
    promoService.getPromosForOrder(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  const likeHandler = (storeId, key) => {
    if (isLoggedIn) {
      let options = {
        storeId,
        setLiked,
        profile,
        data: state?.isFav ? stores?.favStores : stores?.stores,
        dispatch,
        action: state?.isFav
          ? details.homeDelivery
            ? fetchFavStores
            : fetchFavOfflineStores
          : details.homeDelivery
          ? fetchStores
          : fetchOfflineStores,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeStore(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount + 1;
      } else {
        unlikeStore(options);
        if (_.isEmpty(options.data)) details.likesCount = details.likesCount - 1;
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const handleClick = () => {
    if (isLoggedIn) navigate('/profile');
    else setOpenSignIn(true);
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <MynyfyHeader title={storeName || 'Store Page'} />
        <MynyfyEmptyMsg message={['We are unable to fetch the', storeName]} bold={[1]} />
      </Fragment>
    ) : (
      <div id='Stores'>
        {/* <MynyfyHeader
          noBack
          title={details.name}
          customElememt={() => (
            <div className='rowSB'>
              <HomeRounded
                style={{ fontSize: 35 }}
                color='primary'
                className='cursor'
                onClick={() => navigate('/')}
              />

              <ProductCartHelper
                navFrom='seller'
                sellerDetails={details}
                table={urlParams?.table}
              />
            </div>
          )}
        /> */}
        <div id='MynyfyHeader'>
          <Card className='rowSB mynyfyHeader' style={{ padding: '10px' }}>
            <div className='row cursor' style={{ gap: 5 }} onClick={() => setActiveComponent(null)}>
              {state?.storeDetails ? (
                <ChevronLeftRounded
                  style={{ width: 30, height: 30, display: 'block' }}
                  onClick={() => navigate(-1)}
                />
              ) : null}
              <div style={{ width: 40, height: 40, borderRadius: '50%' }}>
                <img
                  src={details?.orgImages[0].url}
                  width={40}
                  height={40}
                  style={{ objectFit: 'fill', borderRadius: '50%' }}
                />
              </div>
              <MynyfyText title={details.name} h5 bold style={{ marginLeft: 10, width: 180 }} />
            </div>

            <div className='row'>
              <ProductCartHelper
                navFrom='seller'
                sellerDetails={details}
                table={urlParams?.table}
              />

              <Person2Outlined
                style={{ width: 30, height: 30, display: 'block', paddingLeft: 5 }}
                className='cursor'
                onClick={handleClick}
              />

              <IconButton onClick={handleDrawerOpen}>
                <Menu />
              </IconButton>
            </div>
          </Card>
          <Drawer
            anchor='top'
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': {
                width: 200,
                top: 60,
                left: 220,
                margin: '0 auto',
                height: 'auto',
              },
            }}
            id='Stores'>
            <div
              style={{ width: '100%', cursor: 'pointer' }}
              role='presentation'
              onClick={handleDrawerClose}
              onKeyDown={handleDrawerClose}>
              <div className='row drawerOption' onClick={() => setActiveComponent('Products')}>
                <StyleOutlined width={20} height={20} />
                <MynyfyText title={isFoodCategory ? 'Menu' : 'Products'} h5 color={'black'} />
              </div>
              <div className='row drawerOption' onClick={() => setActiveComponent('Orders')}>
                <OrdersIcon width={20} height={20} />
                <MynyfyText title={'Orders'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contests')}>
                <ContestsIcon width={20} />
                <MynyfyText title={'Contests'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Coupons')}>
                <CouponsIcon width={20} height={20} />
                <MynyfyText title={'Coupons'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Contact Us')}>
                <ContactPhoneOutlined style={{ color: 'black', width: '20px', height: '20px' }} />
                <MynyfyText title={'Contact Us'} h5 color={'black'} />
              </div>

              <div className='row drawerOption' onClick={() => setActiveComponent('Policies')}>
                <PoliciesIcon width={20} height={20} />
                <MynyfyText title={'Policies'} h5 color={'black'} />
              </div>
            </div>
          </Drawer>
        </div>

        {activeComponent ? (
          <div>{renderComponent()}</div>
        ) : (
          <div style={{ backgroundColor: '#fff' }}>
            {_.isEmpty(details.orgImages) ? (
              <div style={{ padding: '10px 0px', textAlign: 'center' }}>
                <img
                  src={require('../../assets/imgs/icons/noImg.png')}
                  style={{ marginRight: 0 }}
                  className='noStoreImg'
                />
              </div>
            ) : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                interval={3000}>
                {details.orgImages?.map((res, i) => (
                  <div key={i}>
                    <img src={res.url} className={isFoodCategory ? 'storeImg' : 'normalStoreImg'} />
                  </div>
                ))}
              </Carousel>
            )}

            <div>
              <div className='rowSB' style={{ padding: '15px' }}>
                <div className='row'>
                  {
                    isFoodCategory ? (
                      <MynyfyText
                        title={
                          'We deliver within ' +
                          details?.policies?.shippingCharges?.forRestaurant?.deliveryTime +
                          ' min'
                        }
                        bold
                      />
                    ) : null
                    //  (
                    //   <MynyfyText
                    //     title={
                    //       'We deliver within ' +
                    //       details?.policies?.shippingCharges?.outsideState?.forDays +
                    //       ' working days'
                    //     }
                    //     bold
                    //   />
                    // )
                  }
                </div>

                <div className='row'>
                  {isLiked ? (
                    <CircularProgress size={16} />
                  ) : profile?.likedSellers?.includes(details._id) ? (
                    <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                      <FavoriteRounded fontSize='small' className='followBtn' />
                      <MynyfyText title={details.likesCount || 0} bold />
                      <MynyfyText title={' Followers'} style={{ paddingLeft: '3px' }} bold />
                    </div>
                  ) : (
                    <div className='row' onClick={() => likeHandler(details._id, 'like')}>
                      <FavoriteBorderRounded fontSize='small' className='followBtn' />
                      <MynyfyText title={details.likesCount || 0} bold />
                      <MynyfyText title={' Followers'} style={{ paddingLeft: '3px' }} bold />
                    </div>
                  )}
                </div>
              </div>

              {_.isEmpty(bag?.offers) ? null : (
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  showIndicators={false}
                  interval={5000}>
                  {bag?.offers?.map((item, index, array) => (
                    <Card
                      onClick={() => setActiveComponent('Coupons')}
                      key={item?._id}
                      className='offersDisplayCard cursor'>
                      <div className='rowSB'>
                        <div className='row' style={{ gap: 10, textAlign: 'left' }}>
                          <div style={{ width: 60, height: 60 }}>
                            <img src={require('../../assets/imgs/offersImage.png')} />
                          </div>

                          <div>
                            <MynyfyText title={theme.RUPEE + item?.actualPrice + ' OFF'} bold h4 />
                            <MynyfyText
                              title={'On a min order of ' + theme.RUPEE + item.minimumOrderValue}
                              style={{ paddingTop: 4 }}
                            />
                          </div>
                        </div>

                        <MynyfyText title={`${index + 1}/${array.length}`} h6 />
                      </div>
                    </Card>
                  ))}
                </Carousel>
              )}
            </div>

            {/* <div className='rowSB' style={{ padding: '20px 10px 10px' }}>
            <div className='row'>
              <MynyfyText title={details.name} bold h6 className='storeName' />
              <CallRounded color='primary' fontSize='small' />
            </div>
            <div className='row'>
              {isLiked ? (
                <CircularProgress size={16} />
              ) : profile?.likedSellers?.includes(details._id) ? (
                <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                  <FavoriteRounded fontSize='small' className='followBtn' />
                  <MynyfyText title={details.likesCount || 0} />
                </div>
              ) : (
                <div className='row' onClick={() => likeHandler(details._id, 'like')}>
                  <FavoriteBorderRounded fontSize='small' className='followBtn' />
                  <MynyfyText title={details.likesCount || 0} />
                </div>
              )}
            </div>
          </div> */}

            {isFoodCategory ? (
              details.homeDelivery ? null : (
                <div style={{ padding: '0px 20px 5px' }}>
                  <MynyfyText title={'Offline store - Pick-up only'} pSmall />
                </div>
              )
            ) : null}

            {isEcommerceActive ? null : (
              <div style={{ padding: '0px 20px 5px' }}>
                <MynyfyText
                  title={'Currently not in service, please contact support.'}
                  error
                  pSmall
                />
              </div>
            )}

            {isStoreOpened ? null : (
              <div style={{ padding: '0px 20px 5px' }}>
                <MynyfyText
                  title={'Currently store is closed, Please come back at ' + details.storeOpenTime}
                  error
                  pSmall
                />
              </div>
            )}

            <Products
              from={'seller'}
              sellerDetails={details}
              isEcommerceActive={isEcommerceActive}
              type={searchParams.get('type')}
              table={urlParams?.table}
            />

            {/* <MynyfyTab
            noBackBtn
            tabs={[
              details?.ownerId?.categories?.find(
                (e) => e.code == 'Category' && e.data?.toLowerCase().includes('food')
              )
                ? 'Menu'
                : 'Products',
              'About',
              'Contests',
              'Coupons',
            ]}
            tabComponent={[
              <Products
                from={'seller'}
                sellerDetails={details}
                isEcommerceActive={isEcommerceActive}
                type={searchParams.get('type')}
                table={urlParams?.table}
              />,
              <StoreAddress details={details} />,
              <MynyfyTab
                noBackBtn={true}
                tabs={['Active', 'Winners']}
                tabComponent={[
                  <AllContests sellerDetails={details} status='active' from={'seller'} />,
                  <AllContests
                    sellerDetails={details}
                    status='NOACTIVE'
                    from={'seller'}
                    isHistory={true}
                  />,
                ]}
              />,
              <Promos
                type={[PromoType.COUPON, PromoType.POINT_COUPON]}
                isFilter={false}
                emptyListTitle={'Coupons'}
                emptyListMsg={
                  isLoggedIn
                    ? [
                        "You don't have any Coupons",
                        'Get Coupons by participating',
                        'in Contests & Jackpots',
                      ]
                    : 'Please Login for Coupons'
                }
                emptyListMsgBold={isLoggedIn ? [1, 2] : null}
                promos={promos?.promos?.actual}
                sortedPromos={promos?.promos?.sorted}
                action={fetchPromos}
                sortedAction={fetchSortedPromos}
                sellerDetails={details}
                screen={'Coupons'}
                from={'seller'}
              />,
            ]}
          /> */}
          </div>
        )}

        <SignInModal
          open={openSignIn}
          close={() => setOpenSignIn(false)}
          sellerDetails={{
            image: details?.orgImages[0].url,
            storename: details?.name,
          }}
        />
      </div>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default StoreDetails;
