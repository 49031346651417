import React, { Fragment, useEffect, useState, useRef } from 'react';
import productOrderService from '../../services/productOrderService';
import MynyfyButton from '../../components/MynyfyButton';
import MynyfyText from '../../components/MynyfyText';
import { AddRounded, ExpandMore, HighlightOff, RemoveRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../utils/theme';
import _ from 'lodash';
import {
  CustomerSubscriptionType,
  OrderType,
  PaymentGateways,
  PaymentMode,
  PromoType,
  TransactionType,
} from '../../utils/enums';
import paymentService from '../../services/paymentService';
import SelectPromo from '../../components/SelectPromo';
import SelectPaymentMode from '../../components/SelectPaymentMode';
import { RazorPayOptions } from '../../payments/RazorPay';
import { PhonePeGateway } from '../../payments/PhonePe';
import promoService from '../../services/promoService';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyLoader from '../../components/MynfyfLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchIsNewOrder } from '../../redux/actions/newOrder.action';
import { fetchPaymentInitialization } from '../../redux/actions/paymentInitialized.action';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyAlert from '../../components/MynyfyAlert';
import { fetchBagCalculation, fetchBagProducts } from '../../redux/actions/bag.action';
import { shippingChargesKey } from '../../utils/SwitchCases';
import { exchangePolicy, returnPolicy } from '../../utils/CommonPolicies';
import ShowSellerQr from '../../components/ShowSellerQr';
import MynyfyInput from '../../components/MynyfyInput';
import { fileUploadToS3WD } from '../../utils/S3Uploader';
import { merchantTxnId, priceClaculation } from '../../utils/CommonMethods';
import { AES } from 'crypto-js';
import transactionService from '../../services/transactionService';
import { fetchMynts } from '../../redux/actions/mynts.action';
import MynyfySelect from '../../components/MynyfySelect';
import sellerCustomerService from '../../services/sellerCustomerService';
import { DiscountTypes } from '../../utils/DropDownEnums';
import { productFinalPriceHandler } from './AddProductHelper';
import customerSubscriptionService from '../../services/customerSubscriptionService';
import MynyfyModal from '../../components/MynyfyModal';
import MynyfyRadioButton from '../../components/MynyfyRadioButton';
import useScrollToTop from '../../utils/useScrollToTop';

const ProductCart = () => {
  const isUnmounting = useRef(false);
  const paymentDetailsRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  useScrollToTop();

  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  let profile = user.profile?.profile;

  const bag = useSelector((state) => state.bag);
  const appState = useSelector((state) => state.appState);
  const isPaymentInitialized = useSelector((state) => state.isPaymentInitialized);
  const orderMynts = useSelector((state) => state.appData?.orderMynts);

  const [priceDetails, setPriceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [openPromoModal, setOpenPromoModal] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(null);
  const [productOrderId, setProductOrderId] = useState(null);
  const [openPayModeModal, setOpenPayModeModal] = useState(false);
  const [selectedPaymentMode, setPaymentMode] = useState(null);
  const [txnDetails, setTxnDetails] = useState(null);
  const [promos, setPromos] = useState(null);
  const [openDeleteBagModal, setOpenDeleteBagModal] = useState(false);
  const [shippingType, setShippingType] = useState(null);
  const [openSellerUpiModal, setOpenSellerUpiModal] = useState(false);
  const [buyerComments, setBuyerComments] = useState(null);
  const [dineTable, setDineTable] = useState(null);
  const [dineTableNumbers, setDineTableNumbers] = useState({});
  const [error, setError] = useState({});
  const [sellerCustomer, setSellerCustomer] = useState(null);
  const [actualSubcribedPacks, setActualSubcribedPacks] = useState(null);
  const [subcribedPacks, setSubcribedPacks] = useState(null);
  const [selectedPack, setSelectedPack] = useState(null);
  const [openSubcribedPacks, setOpenSubcribedPacks] = useState(false);

  useEffect(() => {
    if (route.state?.canAccess) {
      setIsLoading(true);
      let sorted = _.orderBy(bag?.products, 'name', 'asc');
      setSelectedAddress(route?.state?.deleveryAddress);
      getPrice(sorted);
      setShippingType(route?.state?.shippingType);
      getShippingCharges(bag?.products);
      if (route?.state?.orderType == OrderType.DINE_IN && route?.state?.dineTablesList) {
        getAvailableTables();
      }
      getCustomers();
      getSubcribedPacks();
    } else navigate('/');
  }, []);

  useEffect(() => {
    if (!_.isEmpty(bag?.products) && promos == null && id) getPromos();
    if (!_.isEmpty(bag?.products)) getShippingCharges(bag?.products);
  }, [bag?.products]);

  useEffect(() => {
    if (appState && isPaymentInitialized && txnDetails?.txnId) {
      console.log('called in Product payment');
      phonePePaymentCheck();
    }
  }, [appState]);

  useEffect(() => {
    return () => {
      if (isUnmounting.current) {
        deleteProductId();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  const getCustomers = () => {
    let body = { q: Number(profile?.mobile), forOrder: true, organization: route?.state?.orgId };
    sellerCustomerService.getCustomers(body).then((res) => {
      if (res.success) {
        if (_.isEmpty(res.data)) setSellerCustomer(null);
        else {
          setSellerCustomer(res.data[0]);
          if (!_.isEmpty(bag?.products)) autoDiscountHandler(res.data[0]?.subCategories);
        }
      }
    });
  };

  const autoDiscountHandler = (reqSubCategories) => {
    let clonedData = _.cloneDeep(bag?.products);
    let modifiedData = clonedData?.map((item) => {
      let subCat = reqSubCategories.find((e) => e.subCategory == item.subCategoryId);
      if (subCat?.discount && subCat?.discountType) {
        item.discountType = subCat.discountType;
        item.discount = subCat?.discount;
        item.overallDiscount =
          subCat?.discountType == 'percentage' ? subCat?.discount : subCat?.discount * item.count;
        let discountedPrice = productFinalPriceHandler({
          price: item.price,
          discount: item.overallDiscount,
          discountType: item.discountType,
          gst: Number(item.gst) / 100,
          count: item.count,
        });
        item.discountedPrice = item?.discount ? Number(discountedPrice.priceWithGst) : null;
        item.discountedPriceBeforeGst = item?.discount
          ? Number(discountedPrice.priceWithoutGst)
          : null;
      } else if (item.discountedPrice) {
        delete item.discount;
        delete item.discountedPrice;
        delete item.overallDiscount;
        delete item.discountedPriceBeforeGst;
        item.priceBeforeGst = priceClaculation({ price: item.price, gst: item.gst });
      }
      return item;
    });
    let sorted = _.orderBy(modifiedData, 'name', 'asc');
    dispatch(fetchBagProducts(sorted));
  };

  const getSubcribedPacks = () => {
    if (id) {
      let params = { organization: route?.state?.orgId, buyer: id, isActive: true };
      customerSubscriptionService.getSubscribedPacks(params).then((res) => {
        if (res.success) {
          setActualSubcribedPacks(res.data);
          filterSubcribedPacks({ packs: res.data });
        } else {
          setSubcribedPacks([]);
          setActualSubcribedPacks([]);
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
    }
  };

  function filterSubcribedPacks({ packs, items }) {
    let userPacks = packs || actualSubcribedPacks;
    let reqItems = items || bag?.products;
    if (userPacks && reqItems) {
      let reqPacks = userPacks.filter((obj) => {
        // Check for type 'PASS'
        if (obj.subscriptionId?.type == CustomerSubscriptionType.PASS) {
          return true;
        }

        // Check subCategories and products if they exist (no need for both)
        const hasMatchingSubCats = !_.isEmpty(obj.subscriptionId?.subCategories)
          ? obj.subscriptionId?.subCategories?.some((subCat) =>
              reqItems.some((prod) => prod.subCategoryId == subCat.subCategory)
            )
          : false;
        const hasMatchingProducts = !_.isEmpty(obj.subscriptionId?.products)
          ? obj.subscriptionId?.products.some((subProd) =>
              reqItems.some((prod) => prod.productId == subProd.product)
            )
          : false;

        // Return true if either subCategories or products has a match
        return hasMatchingSubCats || hasMatchingProducts;
      });
      setSubcribedPacks(reqPacks);
      if (selectedPack) {
        let isValid = reqPacks.find((e) => e._id == selectedPack._id);
        if (!isValid) setSelectedPack(null);
      }
    }
  }

  const getAvailableTables = () => {
    let allTables = route?.state?.dineTablesList?.map((e) => ({
      label: e.tableName,
      value: e.tableName,
    }));
    productOrderService
      .getTableOrders({ status: 'ACTIVE', onlyTables: true }, route?.state?.orgId)
      .then((res) => {
        if (res.success) {
          if (!_.isEmpty(res.data)) {
            let tables = res.data.map((e) => e._id);
            let remainingTables = allTables.filter((e) => !tables?.includes(e.value));
            setDineTableNumbers(remainingTables);
            tableAuotSelect(remainingTables);
          } else {
            setDineTableNumbers(allTables);
            tableAuotSelect(allTables);
          }
        } else {
          dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
        }
      });
  };

  const tableAuotSelect = (reqTables) => {
    if (route?.state?.table) {
      let selectedTable = reqTables.find((e) => e.value == route?.state?.table);
      if (selectedTable) setDineTable(selectedTable);
      else {
        dispatch(
          snackbarInfo({
            open: true,
            type: 'error',
            message: ['Selected table is not available', 'Plese select order table'],
          })
        );
      }
    }
  };

  // Sabpaisa callback
  // useEffect(() => {
  //   const messageHandler = (event) => {
  //     if (paymentDetailsRef.current?.isInitiated && paymentDetailsRef.current?.txnId) {
  //       if (
  //         event.data &&
  //         typeof event.data === 'string' &&
  //         !event.data?.includes('webpackHotUpdate')
  //       ) {
  //         let response = JSON.parse(event.data);
  //         if (
  //           response &&
  //           response?.clientTxnId === paymentDetailsRef.current?.txnId &&
  //           response.userId === id
  //         ) {
  //           if (response.status === 'SUCCESS' || response.statusCode === '0000') {
  //             orderConfirmation({
  //               txnId: response.txnId,
  //               extraData: {
  //                 cartId: paymentDetailsRef.current?.cartId,
  //                 payMode: paymentDetailsRef.current?.payMode,
  //                 selectedPromo: paymentDetailsRef.current?.selectedPromo,
  //               },
  //             });
  //           } else {
  //             dispatch(
  //               snackbarInfo({
  //                 open: true,
  //                 type: 'error',
  //                 message: [
  //                   'Sorry!, your transaction is ' + response.status.toLowerCase(),
  //                   'If any amount is debited please contact support',
  //                 ],
  //               })
  //             );
  //             setIsLoading(false);
  //           }
  //         } else if (response?.clientTxnId && paymentDetailsRef.current?.txnId) {
  //           paymentDetailsRef.current = null;
  //           dispatch(
  //             snackbarInfo({
  //               open: true,
  //               type: 'error',
  //               message: [
  //                 'Sorry!, something went wrong',
  //                 'If any amount is debited please contact support',
  //               ],
  //             })
  //           );
  //           setIsLoading(false);
  //         }
  //       } else {
  //         paymentDetailsRef.current = null;
  //         setIsLoading(false);
  //       }
  //     } else {
  //       paymentDetailsRef.current = null;
  //       setIsLoading(false);
  //     }
  //   };
  //   window.addEventListener('message', messageHandler);
  //   return () => {
  //     window.removeEventListener('message', messageHandler);
  //   };
  // }, []);

  const getShippingCharges = (reqProducts) => {
    if (
      route?.state?.policies?.shippingCharges &&
      route?.state?.orderType == OrderType.HOME_DELIVERY
    ) {
      let charges = route?.state?.isRestaurant
        ? route?.state?.policies?.shippingCharges?.forRestaurant?.deliveryCharge
        : route?.state?.policies?.shippingCharges?.[shippingType || 'insideCity'];
      let totalCharges = 0;
      if (route?.state?.isRestaurant) {
        totalCharges += charges;
      } else {
        reqProducts?.map((res) => {
          if (res?.weightRange) {
            let reqRange = shippingChargesKey(res?.weightRange);
            if (reqRange) {
              totalCharges += charges?.[reqRange] * res.count;
            } else {
              totalCharges += 0;
            }
          } else {
            totalCharges += 0;
          }
        });
      }
      setShippingPrice(totalCharges);
    } else {
      setShippingPrice(0);
    }
  };

  const getPromos = () => {
    let brand = [];
    let category = [];
    bag?.products?.map((e) => {
      if (!brand.includes(e.brandId)) brand.push(e.brandId);
      if (!category?.includes(e.categoryId)) category.push(e.categoryId);
    });
    let body = {
      category,
      type: [PromoType.COUPON, PromoType.REWARD],
      organization: route?.state?.orgId,
      noOrg: true,
      buyer: id,
    };
    promoService.getPromosForOrder(body).then((res) => {
      if (res.success) {
        if (_.isEmpty(res.data)) {
          setPromos(res.data);
        } else {
          let coupons = [];
          let rewards = [];
          res.data.map((e) => {
            if (e.type == PromoType.REWARD) rewards.push(e);
            else if (e.type == PromoType.COUPON) coupons.push(e);
          });
          setPromos({
            promosCount: res.data.length,
            promos: [
              { key: 'rewards', title: 'Apply Rewards', data: rewards },
              { key: 'coupons', title: 'Apply Coupons', data: coupons },
            ],
          });
        }
      } else {
        setPromos([]);
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  };

  const addressHandler = (key) => {
    return key ? `${key}, ` : null;
  };

  const incrementHandler = (id) => {
    setIsLoading(true);
    let clonedData = _.cloneDeep(bag?.products);
    let product = clonedData.find((e) => e.productId == id);
    if (!_.isEmpty(product)) {
      product.count = product.count + 1;
      if (product.discount) {
        product.overallDiscount = product.discount * product.count;
      }
      let modifiedData = clonedData.filter((e) => e.productId !== id);
      modifiedData.push(product);
      let sorted = _.orderBy(modifiedData, 'name', 'asc');
      deleteProductId();
      dispatch(fetchBagProducts(sorted));
      getPrice(sorted);
    }
  };

  const decrementHandler = (id) => {
    setIsLoading(true);
    let clonedData = _.cloneDeep(bag?.products);
    let product = clonedData.find((e) => e.productId == id);
    if (!_.isEmpty(product)) {
      if (product.count > 0) {
        product.count = product.count - 1;
        if (product.discount) {
          product.overallDiscount = product.discount * product.count;
        }
        let modifiedData = clonedData.filter((e) => e.productId !== id);
        if (product.count > 0) {
          modifiedData.push(product);
        }
        let sorted = _.orderBy(modifiedData, 'name', 'asc');
        if (_.isEmpty(sorted)) {
          setOpenDeleteBagModal(true);
        } else {
          deleteProductId();
          dispatch(fetchBagProducts(sorted));
          getPrice(sorted);
        }
      }
    }
  };

  const deleteCart = () => {
    deleteProductId();
    setPriceDetails({ price: 0, tax: 0, shippingPrice, totalPrice: 0 });
    dispatch(fetchBagProducts(null));
    dispatch(fetchBagCalculation(null));
    setOpenDeleteBagModal(false);
    setIsLoading(false);
    navigate(-1);
  };

  const deleteProductId = () => {
    if (productOrderId) {
      productOrderService.deleteProductOrder(productOrderId).then((res) => console.log(res));
      setProductOrderId(null);
    }
  };

  const getPrice = (modifiedData) => {
    setIsLoading(true);
    let reqObj = _.isEmpty(modifiedData) ? bag?.products : modifiedData;
    if (!_.isEmpty(reqObj)) {
      productOrderService.getProductOrderPrice({ products: reqObj }).then((res) => {
        if (res.success) {
          setPriceDetails(res.data);
          if (selectedPromo) {
            let isMinimumOrderValuePassed = selectedPromo.minimumOrderValue
              ? res.data?.price >= selectedPromo.minimumOrderValue
                ? true
                : false
              : true;
            if (!isMinimumOrderValuePassed) setSelectedPromo(null);
          }
        } else {
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message.message }));
          dispatch(fetchBagProducts(res.message.products));
        }
        setIsLoading(false);
      });
    }
  };

  const getPoints = () => {
    transactionService.getPoints().then((res) => {
      if (res?.data?.points) dispatch(fetchMynts(res?.data?.points));
    });
  };

  const orderValidation = () => {
    let isDineTableSelected =
      route?.state?.orderType == OrderType.DINE_IN && route.state?.dineTablesList
        ? dineTable
        : true;
    if (
      priceDetails?.totalPrice &&
      ((route?.state?.orderType == OrderType.HOME_DELIVERY && selectedAddress) ||
        route?.state?.orderType) &&
      (shippingPrice || shippingPrice == 0) &&
      isDineTableSelected
    ) {
      setError({});
      let cartValue = finalPriceHandler();
      if (cartValue == 0) {
        paymentModeHandler(PaymentMode.CASH);
      } else {
        setOpenPayModeModal(true);
        // if (dineTable) paymentModeHandler(PaymentMode.CASH);
        // else setOpenPayModeModal(true);
      }
    } else {
      if (!isDineTableSelected) setError({ dineTable: 'Please select table number' });
      else setError({});
      if (route?.state?.orderType == OrderType.HOME_DELIVERY && !selectedAddress) {
        dispatch(
          snackbarInfo({ type: 'warning', open: true, message: 'Please select delivery address' })
        );
      } else if (route?.state?.orderType == OrderType.HOME_DELIVERY && !shippingPrice) {
        dispatch(
          snackbarInfo({
            type: 'error',
            open: true,
            message: ['Sorry, Unable to get shipping charges', 'Please try after sometime'],
          })
        );
      } else if (!priceDetails) {
        dispatch(
          snackbarInfo({
            type: 'error',
            open: true,
            message: ['Sorry, Something went wrong', 'Please try after sometime'],
          })
        );
      }
    }
  };

  const submitOrder = async (payMode, payImg) => {
    let price = _.clone(priceDetails);
    price.shippingCharges = shippingPrice;
    price.discount = discountValue();
    price.productsDiscount = productsDiscountValue();
    price.totalPrice = finalPriceHandler();
    setIsLoading(true);
    let brand = [];
    bag?.products.map((e) => {
      if (!brand?.includes(e.brandId)) brand.push(e.brandId);
    });
    let data = {
      products: bag?.products,
      priceDetails: price,
      paymentMode:
        payMode == PaymentMode.CASH
          ? PaymentMode.CASH
          : payMode == PaymentMode.UPI_QR
          ? PaymentMode.UPI_QR
          : PaymentMode.ONLINE,
      buyer: id || null,
      seller: route?.state?.orgId,
      fullAddress: selectedAddress || null,
      brand,
      orderType: route?.state?.orderType,
    };
    if (buyerComments) data.buyerComments = buyerComments;
    if (dineTable) data.dineTableNumber = dineTable?.value;
    if (selectedPromo) data.couponUsed = selectedPromo?._id || selectedPromo;
    if (price.totalPrice == 0) data.paymentMode = PaymentMode.CASH;
    if (sellerCustomer) {
      data.isMyCustomer = true;
    } else {
      data.isMyCustomer = false;
      data.buyerProfileId = user?.user?.profile;
    }
    if (selectedPack) {
      data.subscribedPack = { id: selectedPack._id };
      if (selectedPack?.subscriptionId?.type == CustomerSubscriptionType.PASS) {
        price.subscriptionPassDiscount = passDiscountValue();
        data.subscribedPack.amount = price.subscriptionPassDiscount;
      } else {
        data.subscribedPack.count = noOfPackServices()?.count;
      }
    }
    if (!id) data.guestUser = true;
    let s3ErrorMsg = null;
    if (payImg) {
      await fileUploadToS3WD(payImg, 'OrderPayment').then((res) => {
        if (res.status !== 403 && res.url) {
          data.paymentImg = res;
          s3ErrorMsg = null;
        } else {
          s3ErrorMsg = 'Unable to upload store image please try again';
        }
      });
    }
    if (s3ErrorMsg) {
      dispatch(snackbarInfo({ open: true, type: 'warning', message: s3ErrorMsg }));
      setIsLoading(false);
    } else {
      productOrderService
        .createProductOrder(data)
        .then((res) => {
          if (res.success) {
            if (payMode == PaymentMode.CASH || payMode == PaymentMode.UPI_QR) {
              dispatch(snackbarInfo({ type: 'success', open: true, message: res.message }));
              if (dineTable) {
                if (bag?.about?.urlId) {
                  navigate('/Stores/' + bag?.about?.urlId + '/' + dineTable?.value, {
                    replace: true,
                  });
                } else navigate('/');
              } else {
                if (selectedPromo && selectedPromo?.points) getPoints();
                dispatch(fetchIsNewOrder(true));
                dispatch(fetchBagCalculation(null));
                dispatch(fetchBagProducts(null));
                navigate('/Orders', { replace: true });
              }
            } else {
              setProductOrderId(res.data?.id);
              if (payMode == PaymentMode.RAZOR_PAY) {
                initiateRazorPayPayment({ cartId: res.data?.id, payMode: PaymentMode.ONLINE });
              } else if (payMode == PaymentMode.UPI) {
                initiatePhonePePayment({ cartId: res.data?.id, payMode: PaymentMode.ONLINE });
              } else if (payMode == PaymentMode.SAB_PAISA) {
                initiateSabpaisaPayment({ cartId: res.data?.id, payMode: PaymentMode.ONLINE });
              }
            }
          } else {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message.message }));
            setIsLoading(false);
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const initiateRazorPayPayment = ({ cartId, payMode }) => {
    setIsLoading(true);
    let totalDiscount = discountValue() + productsDiscountValue();
    let price = priceDetails?.totalPrice + (shippingPrice + 0) - totalDiscount;
    paymentService
      .initiateRazorPayPayment({ amount: Number(price), type: TransactionType.PRODUCT_ORDER })
      .then((res) => {
        if (res.success) {
          razorPayPaymentHandler({
            amount: res.data.amount,
            orderId: res.data.orderId,
            cartId,
            payMode,
          });
        } else {
          setIsLoading(false);
          dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
        }
      })
      .catch((e) => setIsLoading(false));
  };

  const initiatePhonePePayment = ({ cartId, payMode }) => {
    setIsLoading(true);
    let price = priceDetails?.totalPrice + (shippingPrice + 0) - (selectedPromo?.actualPrice || 0);
    PhonePeGateway.phonePeUPIPaymentObj({
      userId: id,
      amount: price,
      mobileNumber: profile?.mobile,
      type: TransactionType.PRODUCT_ORDER,
    }).then((res) => {
      if (res.success) {
        PhonePeGateway.initiatePayment({ data: res.paymentObj, dispatch, action: snackbarInfo })
          .then((res) => {
            if (res.success) {
              setTxnDetails({ txnId: res.txnId, extraData: { cartId, payMode } });
              dispatch(fetchPaymentInitialization(true));
              window.open(res.txnLink);
            }
          })
          .catch((e) => console.log(e))
          .finally(() => setIsLoading(false));
      } else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
      }
    });
  };

  const initiateSabpaisaPayment = ({ cartId, payMode }) => {
    setIsLoading(true);
    let price = priceDetails?.totalPrice + (shippingPrice + 0) - (selectedPromo?.actualPrice || 0);
    let myTxnId = merchantTxnId({
      userId: id,
      mobile: profile?.mobile,
      gateway: PaymentGateways.SAB_PAISA,
    });
    let body = {
      payerName: profile.firstName,
      payerEmail: profile.email,
      payerMobile: profile.mobile,
      clientTxnId: myTxnId,
      amount: price,
      udf1: 'Product Order',
      udf3: 'browser',
      udf4: 'ProductOrder',
      udf5: cartId,
      udf6: id,
      udf7: 'BuyerProfile',
    };
    let encData = AES.encrypt(JSON.stringify(body), process.env.REACT_APP_PAYMENT_PAYLOAD_KEY);
    paymentDetailsRef.current = {
      isInitiated: true,
      txnId: myTxnId,
      cartId,
      payMode,
      selectedPromo,
    };
    window.open(
      `${process.env.REACT_APP_PG_API_HOST}/sabpaisaPayInPaymentRequest?encStr=${encData}`
    );
  };

  const razorPayPaymentHandler = ({ amount, orderId, cartId, payMode }) => {
    let options = RazorPayOptions({
      amount,
      orderId,
      description: 'Your bag value (includes GST)',
      user: user?.user,
    });
    options.handler = (res) => {
      res.type = PaymentGateways.RAZOR_PAY;
      if (res.razorpay_order_id && res.razorpay_payment_id && res.razorpay_signature) {
        orderConfirmation({ paymentDetails: res, extraData: { cartId, payMode } });
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: 'Payment Failed' }));
      }
    };
    options.modal = { ondismiss: () => setIsLoading(false) };

    const rzpay = new window.Razorpay(options);
    rzpay.open();
  };

  const phonePePaymentCheck = () => {
    PhonePeGateway.transactionStatusCheck({
      txnId: txnDetails?.txnId,
      submitHandler: orderConfirmation,
      extraData: txnDetails.extraData,
      clickHandler: (loading) => {
        setIsLoading(loading);
        let clonedDetails = _.clone(txnDetails);
        clonedDetails.txnId = null;
        setTxnDetails(clonedDetails);
        dispatch(fetchPaymentInitialization(false));
      },
      dispatch,
      action: snackbarInfo,
    });
  };

  const orderConfirmation = ({ paymentDetails, extraData, txnId }) => {
    setIsLoading(true);
    setTxnDetails(null);
    dispatch(fetchPaymentInitialization(false));
    let body = { id: extraData.cartId, paymentMode: extraData.payMode };
    if (paymentDetails) {
      body.paymentDetails = paymentDetails;
    }
    if (txnId) {
      body.txnId = txnId;
    }
    if (selectedPromo || extraData.selectedPromo) {
      body.couponUsed = selectedPromo || extraData.selectedPromo;
    }
    productOrderService
      .confirmProductOrder(body)
      .then((res) => {
        if (res.success) {
          dispatch(snackbarInfo({ type: 'success', open: true, message: res.message }));
          if (dineTable) {
            if (bag?.about?.urlId) {
              navigate('/Stores/' + bag?.about?.urlId + '/' + dineTable?.value, {
                replace: true,
              });
            } else navigate('/');
          } else {
            if (body.couponUsed?.points) getPoints();
            dispatch(fetchIsNewOrder(true));
            navigate('/Orders', { replace: true });
          }
        } else {
          if (res?.message?.includes('Payment Signature failed')) {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
            dispatch(fetchIsNewOrder(true));
            navigate('/Orders', { replace: true });
          } else {
            dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const paymentModeHandler = (e) => {
    setPaymentMode(e);
    if (productOrderId) {
      if (e == PaymentMode.CASH) {
        orderConfirmation({ extraData: { cartId: productOrderId, payMode: PaymentMode.CASH } });
      } else if (e == PaymentMode.RAZOR_PAY) {
        initiateRazorPayPayment({ cartId: productOrderId, payMode: PaymentMode.ONLINE });
      } else if (e == PaymentMode.UPI) {
        initiatePhonePePayment({ cartId: productOrderId, payMode: PaymentMode.ONLINE });
      } else if (e == PaymentMode.UPI_QR) {
        orderConfirmation({ extraData: { cartId: productOrderId, payMode: PaymentMode.UPI_QR } });
      } else if (e == PaymentMode.SAB_PAISA) {
        initiateSabpaisaPayment({ cartId: productOrderId, payMode: PaymentMode.ONLINE });
      }
    } else if (e == PaymentMode.UPI_QR) {
      setOpenPayModeModal(false);
      setTimeout(() => setOpenSellerUpiModal(true), 200);
    } else {
      submitOrder(e);
    }
  };

  const discountValue = () => {
    let couponDiscount = selectedPromo?.actualPrice || 0;
    let productsDiscount = productsDiscountValue();
    let totalDiscount = couponDiscount + productsDiscount;
    let cartValue = (shippingPrice || 0) + (priceDetails?.price || 0);
    let finalDiscount =
      couponDiscount == 0
        ? 0
        : totalDiscount > cartValue
        ? productsDiscount >= cartValue
          ? 0
          : cartValue - productsDiscount
        : couponDiscount;
    return finalDiscount;
  };

  const productsDiscountValue = () => {
    let productsDiscount = bag?.products.reduce((acc, e) => {
      return e.discountedPrice ? acc + (e.price - e.discountedPrice) * e.count : acc;
    }, 0);
    return productsDiscount;
  };

  const totalCartValue = () => {
    let price = priceDetails?.totalPrice;
    let discount = discountValue();
    let productsDiscount = productsDiscountValue();
    let finalPrice = price + (shippingPrice || 0) - discount - productsDiscount;
    return finalPrice || finalPrice == 0 ? finalPrice : 'NA';
  };

  const finalPriceHandler = () => {
    return totalCartValue() - passDiscountValue();
  };

  const orderMyntsHandler = () => {
    let myntsMultiple = Math.floor(finalPriceHandler() / orderMynts.quotePoints);
    let myntsForOrder = Number(myntsMultiple) * Number(orderMynts.data);
    return myntsForOrder;
  };

  const selectPackHandler = (pack) => {
    let clonedPack = _.cloneDeep(pack);
    if (pack?.subscriptionId?.type == CustomerSubscriptionType.PACK) {
      let utilizedCount =
        (clonedPack?.count || 0) +
        (clonedPack?.hold?.reduce((acc, curr) => acc + curr.count, 0) || 0);
      if (clonedPack?.subscriptionId?.count > utilizedCount) {
        setSelectedPack(clonedPack);
        let clonedProducts = _.cloneDeep(bag?.products);
        clonedProducts.map((e) => {
          let subProd = clonedPack?.subscriptionId?.products?.find((f) => f.product == e.productId);
          if (subProd) {
            subscriptionDiscountApplyHandler(e, subProd);
          } else {
            let subCat = clonedPack?.subscriptionId?.subCategories?.find(
              (f) => f.subCategory == e.subCategoryId
            );
            if (subCat) subscriptionDiscountApplyHandler(e, subCat);
          }
        });
        dispatch(fetchBagProducts(clonedProducts));
      } else {
        dispatch(
          snackbarInfo({
            open: true,
            type: 'warning',
            message: 'Subscription Pack services limit is exceeded',
          })
        );
      }
    } else {
      let utilizedAmount =
        (clonedPack?.discountAvailed || 0) -
        (clonedPack?.hold?.reduce((acc, curr) => acc + curr.amount, 0) || 0);
      if (clonedPack?.subscriptionId?.amount > utilizedAmount) {
        setSelectedPack(clonedPack);
        autoDiscountHandler(sellerCustomer?.subCategories);
      } else {
        dispatch(
          snackbarInfo({
            open: true,
            type: 'warning',
            message: 'Subscription Pass amount limit is exceeded',
          })
        );
      }
    }
  };

  const subscriptionDiscountApplyHandler = (item, discountObj) => {
    if (discountObj?.discount && discountObj?.discountType) {
      item.discountType = discountObj.discountType;
      item.discount = discountObj?.discount;
      item.overallDiscount =
        discountObj?.discountType == 'percentage'
          ? discountObj?.discount
          : discountObj?.discount * item.count;
      let discountedPrice = productFinalPriceHandler({
        price: item.price,
        discount: item.overallDiscount,
        discountType: item.discountType,
        gst: Number(item.gst) / 100,
        count: item.count,
      });
      item.discountedPrice = Number(discountedPrice.priceWithGst);
      item.discountedPriceBeforeGst = Number(discountedPrice.priceWithoutGst);
    }
  };

  const passDiscountValue = () => {
    if (selectedPack?.subscriptionId?.type == CustomerSubscriptionType.PASS) {
      let passAmount = selectedPack.subscriptionId?.amount - (selectedPack.discountAvailed || 0);
      return totalCartValue() > passAmount ? passAmount : totalCartValue();
    } else return 0;
  };

  const noOfPackServices = () => {
    if (selectedPack?.subscriptionId?.type == CustomerSubscriptionType.PACK) {
      let pack = selectedPack?.subscriptionId;
      let clonedProducts = _.cloneDeep(bag?.products);
      let count = 0;
      let amount = 0;
      clonedProducts.map((e) => {
        let subProd = pack?.products?.find((f) => f.product == e.productId);
        if (subProd && subProd.discount == e.discount && subProd.discountType == e.discountType) {
          count = count + e.count;
          amount = amount + (e.price - e.discountedPrice) * e.count;
        } else {
          let subCat = pack?.subCategories?.find((f) => f.subCategory == e.subCategoryId);
          if (subCat && subCat.discount == e.discount && subCat.discountType == e.discountType) {
            count = count + e.count;
            amount = amount + (e.price - e.discountedPrice) * e.count;
          }
        }
      });
      return { count, amount };
    } else return { count: 0, amount: 0 };
  };

  return bag?.products ? (
    <Fragment>
      <div id='Products' style={{ paddingBottom: 0 }}>
        <div className='cartMainContainer'>
          <div>
            <MynyfyHeader title={route?.state?.name} />
            <div className='productCartContainer' style={{ marginTop: '-2px' }}>
              <MynyfyText title='Order Details' pBig bold center />
            </div>
            <div className='productCartContainer' style={{ marginTop: 4 }}>
              <div className='row'>
                <MynyfyText bold title={'Bag Items'} className='heading' style={{ flex: 6 }} />
                <div style={{ flex: 2, textAlign: 'right' }}>
                  <MynyfyText bold title={'Rate'} className='heading' />
                  <MynyfyText bold title={'(Inc. GST)'} className='heading' />
                </div>
                <div style={{ flex: 2, textAlign: 'right' }}>
                  <MynyfyText bold title={'Amount'} className='heading' />
                  <MynyfyText bold title={'(Inc. GST)'} className='heading' />
                </div>
              </div>
              <div>
                {bag?.products.map((res, i) => (
                  <div
                    style={{ marginTop: 12, marginBottom: bag?.products?.length == i + 1 ? 0 : 15 }}
                    key={i}>
                    <div>
                      <MynyfyText title={res.name} p />
                      {res.remainingCount ? (
                        res.remainingCount < res.count ? (
                          <MynyfyText
                            title={`Only ${res.remainingCount} ${
                              res.remainingCount > 1 ? 'products' : 'product'
                            } available`}
                            className='productErr'
                          />
                        ) : null
                      ) : null}
                    </div>

                    <div className='rowSB' style={{ marginTop: 15 }}>
                      <div className='row' style={{ flex: 6 }}>
                        <div className='rowSB changeProductConatiner'>
                          <div onClick={() => decrementHandler(res.productId)}>
                            <RemoveRounded fontSize='small' className='productIcon link' />
                          </div>
                          <MynyfyText title={res.count || 0} small bold center />
                          <div onClick={() => incrementHandler(res.productId)}>
                            <AddRounded fontSize='small' className='productIcon link' />
                          </div>
                        </div>
                        {res.overallDiscount ? (
                          <Fragment>
                            <div className='row discountTypeCountainer'>
                              <MynyfyText
                                title={
                                  DiscountTypes.find((e) => e.value == res.discountType)?.label ||
                                  ''
                                }
                                h6
                                style={{ paddingLeft: 10, color: '#9e9e9e' }}
                              />
                              <ExpandMore style={{ color: 'hsl(0, 0%, 80%)' }} />
                            </div>
                            <div>
                              <MynyfyInput
                                placeHolder={'Discount'}
                                style={{ margin: 0, fontSize: 13, width: 80 }}
                                value={res.overallDiscount || ''}
                                onChange={(e) => {}}
                                className='productDiscountField'
                                InputProps={{
                                  style: { fontSize: 13 },
                                  classes: {
                                    input: 'productDiscountFieldHeight',
                                    notchedOutline: 'productDiscountFieldOutline',
                                  },
                                }}
                                disabled
                              />
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                      <div style={{ flex: 2 }}>
                        <MynyfyText
                          title={`${theme.RUPEE}${res.price}`}
                          right
                          style={{
                            textDecoration: res.discountedPrice ? 'line-through' : 'unset',
                          }}
                        />
                        {res.discountedPrice ? (
                          <MynyfyText
                            title={`${theme.RUPEE}${res.discountedPrice}`}
                            right
                            error={res.discountedPrice < 0 ? true : false}
                          />
                        ) : null}
                      </div>
                      <div style={{ flex: 2, marginRight: 5 }}>
                        <MynyfyText
                          title={`${theme.RUPEE}${res.price * res.count}`}
                          right
                          style={{
                            textDecoration: res.discountedPrice ? 'line-through' : 'unset',
                          }}
                        />
                        {res.discountedPrice ? (
                          <MynyfyText
                            title={`${theme.RUPEE}${(res.discountedPrice * res.count).toFixed(2)}`}
                            right
                            error={res.discountedPrice < 0 ? true : false}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {selectedAddress ? (
              <Fragment>
                <div className='productCartContainer' style={{ marginTop: 4 }}>
                  <div className='heading'>
                    <MynyfySpan bold title={'Delivery Address'} />
                    {selectedAddress.type ? (
                      <MynyfySpan bold title={' - ' + selectedAddress.type} />
                    ) : null}
                  </div>

                  <div style={{ marginTop: '8px' }}>
                    <div>
                      <MynyfySpan title={addressHandler(selectedAddress.lane1)} />
                      <MynyfySpan title={addressHandler(selectedAddress.lane2)} />
                      <MynyfySpan title={addressHandler(selectedAddress.locality)} />
                      <MynyfySpan title={addressHandler(selectedAddress.landMark)} />
                      <MynyfySpan title={addressHandler(selectedAddress.city)} />
                      <MynyfySpan title={addressHandler(selectedAddress.state)} />
                      <MynyfySpan title={selectedAddress.pincode} />
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : null}
            <div
              className='productCartContainer link'
              style={{ marginTop: 4 }}
              onClick={() => setOpenPromoModal(true)}>
              <MynyfyText
                bold
                title={`Rewards & Coupons ${selectedPromo ? '- Applied' : ''}`}
                className='heading'
              />
              <div className='rowSB' style={{ marginTop: 8 }}>
                <div>
                  <MynyfySpan title={selectedPromo ? selectedPromo.name : 'Apply Offers'} />
                  {selectedPromo ? null : (
                    <MynyfySpan title={` (${promos?.promosCount || 0})`} primary />
                  )}
                </div>
                <MynyfyText
                  title={selectedPromo ? theme.RUPEE + selectedPromo.actualPrice : ''}
                  bold
                  color={'green'}
                />
              </div>
            </div>
            <div
              className='productCartContainer link'
              style={{ marginTop: 4 }}
              onClick={() => setOpenSubcribedPacks(true)}>
              <MynyfyText bold title={'Subscription Packs'} className='heading' />
              <div className='rowSB' style={{ marginTop: 8 }}>
                <div>
                  <MynyfySpan
                    title={
                      selectedPack
                        ? selectedPack.name ||
                          (selectedPack.subscriptionId?.type == CustomerSubscriptionType.PASS
                            ? theme.RUPEE + selectedPack.subscriptionId?.amount + ' Pass'
                            : selectedPack.subscriptionId?.count +
                              ' Services/Items Subscription Pack')
                        : 'Apply Subscription Packs'
                    }
                  />
                  {selectedPack ? null : (
                    <MynyfySpan title={` (${subcribedPacks?.length || 0})`} primary />
                  )}
                </div>
                {selectedPack ? (
                  <MynyfyText
                    title={
                      theme.RUPEE +
                      (selectedPack.subscriptionId?.type == CustomerSubscriptionType.PASS
                        ? passDiscountValue()
                        : noOfPackServices()?.amount + ' (' + noOfPackServices()?.count + ')')
                    }
                    bold
                    color={'green'}
                  />
                ) : null}
              </div>
            </div>

            <div className='productCartContainer' style={{ marginTop: 4 }}>
              <MynyfyText bold title={'Bill Details'} className='heading' />
              <div>
                <div className='rowSB'>
                  <div style={{ marginTop: '8px' }}>
                    <MynyfySpan title={'Products/Services Total'} />
                    <MynyfySpan title={' (Including GST)'} small />
                  </div>
                  <MynyfyText title={`${theme.RUPEE}${priceDetails?.price || 'NA'}`} p />
                </div>
                {/* <div className='rowSB' style={{ paddingTop: 10 }}>
                  <MynyfyText title={'Tax'} />
                  <MynyfyText title={`${theme.RUPEE}${priceDetails?.tax || 0}`} p />
                </div> */}
                {shippingPrice == 0 ? null : (
                  <div className='rowSB' style={{ paddingTop: 10 }}>
                    <div>
                      <MynyfyText title={'Shipping Charges'} />
                      {shippingPrice || shippingPrice == 0 ? null : (
                        <MynyfyText
                          title={'(select delivery address)'}
                          style={{ fontSize: 10, marginTop: 2 }}
                        />
                      )}
                    </div>
                    <MynyfyText
                      title={
                        shippingPrice || shippingPrice == 0
                          ? `${theme.RUPEE}${shippingPrice}`
                          : '----'
                      }
                      p
                    />
                  </div>
                )}
                <div className='rowSB' style={{ paddingTop: 10 }}>
                  <MynyfyText title={'Coupon Discount'} />
                  <MynyfyText title={`${theme.RUPEE}${discountValue()}`} color={'green'} p />
                </div>
                {productsDiscountValue() ? (
                  <div className='rowSB' style={{ paddingTop: 10 }}>
                    <MynyfyText title={'Product Discount'} />
                    <MynyfyText
                      title={`${theme.RUPEE}${productsDiscountValue()}`}
                      color={'green'}
                    />
                  </div>
                ) : null}
                {selectedPack &&
                selectedPack?.subscriptionId?.type == CustomerSubscriptionType.PASS ? (
                  <div className='rowSB' style={{ marginTop: 8 }}>
                    <MynyfyText title={'Pass Amount'} />
                    <MynyfyText
                      title={`${theme.RUPEE}${passDiscountValue() || 0}`}
                      color={'green'}
                    />
                  </div>
                ) : null}
                <div className='divider' />
                <div className='rowSB'>
                  <MynyfyText title={'To Pay'} p bold />
                  <MynyfyText title={`${theme.RUPEE}${finalPriceHandler()}`} p bold />
                </div>
              </div>
            </div>

            {route?.state?.policies ? (
              <div className='row' style={{ marginTop: 4 }}>
                {_.isEmpty(route?.state?.policies?.shipping) || route?.state?.isOffline ? null : (
                  <div
                    className='productCartContainer cursor'
                    style={{ padding: '1px', paddingRight: '12px' }}>
                    <div
                      className='servicePolicy'
                      onClick={() => {
                        navigate(`/Policies/${bag?.about?.name}ShippingPolicy`, {
                          state: {
                            policy: route?.state?.policies?.shippingCharges
                              ? [
                                  {
                                    heading: 'Delivery Timeline:',
                                    content: [
                                      route?.state?.policies?.shippingCharges?.forRestaurant
                                        ? `We will deliver your order in ${route?.state?.policies?.shippingCharges?.forRestaurant?.deliveryTime} minutes`
                                        : `We will ship you products in ${
                                            route?.state?.policies?.shippingCharges?.[
                                              shippingType || 'insideCity'
                                            ]?.forDays
                                          } working days`,
                                    ],
                                  },
                                ]
                              : [{ heading: null, content: [''] }],
                            policy2: route?.state?.policies?.shipping,
                            policyName: `${bag?.about?.name} Shipping / Delivery Policy`,
                            isRestaurant: route?.state?.isRestaurant,
                          },
                        });
                      }}>
                      <MynyfyText title={'Shipping / Delivery Policy'} />
                    </div>
                  </div>
                )}
                <div
                  className='productCartContainer cursor'
                  style={{
                    padding: '1px',
                    marginLeft:
                      _.isEmpty(route?.state?.policies?.shipping) || route?.state?.isOffline
                        ? 0
                        : 5,
                  }}>
                  <div
                    className='servicePolicy'
                    onClick={() => {
                      navigate(`/Policies/${bag?.about?.name}RefundPolicy`, {
                        state: {
                          policy: route?.state?.policies?.isReturn
                            ? route?.state?.policies?.refund
                            : returnPolicy,
                          policy2: route?.state?.policies?.isExchange
                            ? route?.state?.policies?.exchange
                            : exchangePolicy,
                          policyName: `${bag?.about?.name} Return / Refund / Exchange Policy`,
                        },
                      });
                    }}>
                    <MynyfyText title={'Return/Refund/Exchange Policy'} />
                  </div>
                </div>
              </div>
            ) : null}
            <div className='productCartContainer' style={{ marginTop: 4 }}>
              <MynyfyText bold title={'Provide instructions to store if any'} className='heading' />
              <MynyfyInput
                placeHolder={'Comments'}
                fullWidth={true}
                multiline={true}
                minRows={4}
                maxRows={8}
                value={buyerComments}
                onChange={(e) => setBuyerComments(e.target.value)}
                style={{ margin: '8px 0px 0px' }}
              />
            </div>
            {route?.state?.isRestaurant && route?.state?.orderType == OrderType.TAKE_AWAY ? (
              <div className='productCartContainer' style={{ marginTop: 4 }}>
                <MynyfyText
                  title={'I am aware that I need to pick-up from the store.'}
                  className='heading'
                />
              </div>
            ) : null}
            {route?.state?.orderType == OrderType.DINE_IN && route?.state?.dineTablesList ? (
              <div className='productCartContainer' style={{ marginTop: 4 }}>
                <MynyfyText bold title={'Please select table'} className='heading' />
                <MynyfySelect
                  placeholder={'Select Table'}
                  value={dineTable}
                  error={error.dineTable ? true : false}
                  helperText={error.dineTable}
                  onChange={(e) => setDineTable(e)}
                  list={dineTableNumbers}
                  menuPlacement={'top'}
                  disabled={route?.state?.table && dineTable ? true : false}
                />
              </div>
            ) : null}
            {orderMynts ? (
              <div className='productCartContainer link' style={{ marginTop: 4 }}>
                <MynyfySpan title={'You will earn '} />

                <img
                  src={require('../../assets/imgs/icons/coin.png')}
                  style={{ width: 16, margin: '0 2px -3px 0' }}
                />
                <MynyfySpan
                  bold
                  title={orderMyntsHandler() + ' Mynyfy Mynts'}
                  className='heading'
                />

                <MynyfySpan title={' on this purchase'} />
              </div>
            ) : null}
          </div>
          <div style={{ padding: '0px 20px' }}>
            <div className='divider' style={{ borderColor: '#c0c0c0' }} />
            <div style={{ padding: '10px 20px 20px', marginTop: '3px' }}>
              <MynyfyButton
                label={'Place Order'}
                className='placeOrderBtn'
                containerStyle={{ textAlign: 'center' }}
                style={{ fontSize: 18, fontFamily: theme.BOLD_FONT }}
                disabled={isLoading}
                loading={isLoading}
                onClick={() => orderValidation()}
              />
            </div>
          </div>
        </div>
      </div>
      <SelectPromo
        isVisible={openPromoModal}
        closeModal={() => setOpenPromoModal(false)}
        selectedPromo={selectedPromo}
        setData={(e) => setSelectedPromo(e)}
        promos={_.isEmpty(promos) ? [] : promos?.promos}
        totalProductsValue={priceDetails?.price}
      />
      <SelectPaymentMode
        isVisible={openPayModeModal}
        closeModal={() => setOpenPayModeModal(false)}
        selectedPaymentMode={selectedPaymentMode}
        setData={(e) => paymentModeHandler(e)}
        payModes={route?.state?.orgPayModes}
      />
      <ShowSellerQr
        isVisible={openSellerUpiModal}
        closeModal={() => setOpenSellerUpiModal(false)}
        submitOrder={(payImg) => submitOrder(PaymentMode.UPI_QR, payImg)}
        upiId={route.state?.upiId}
        upiQr={route.state?.upiQr}
        name={route.state?.name}
      />
      <MynyfyAlert
        open={openDeleteBagModal}
        close={() => setOpenDeleteBagModal(false)}
        title={'Do you want to delete the bag?'}
        action1={{
          label: 'No',
          handler: () => {
            setIsLoading(false);
            deleteProductId();
            setOpenDeleteBagModal(false);
          },
        }}
        action2={{ label: 'Yes', handler: () => deleteCart() }}
      />

      {/* Customer Subscribed Packs */}
      <MynyfyModal open={openSubcribedPacks} close={() => setOpenSubcribedPacks(false)}>
        <div style={{ maxHeight: '75%', backgroundColor: '#fff' }}>
          <div className='rowSB' style={{ paddingBottom: 10, paddingHorizontal: 3 }}>
            <MynyfyText title={'Apply Subscribed Pack'} bold h5 />
            {selectedPack ? (
              <MynyfyText
                title={'Remove'}
                error
                bold
                onClick={() => {
                  setSelectedPack(null);
                  setOpenSubcribedPacks(false);
                  autoDiscountHandler(sellerCustomer?.subCategories);
                }}
                className='cursor'
              />
            ) : (
              <HighlightOff
                onClick={() => setOpenSubcribedPacks(false)}
                color='error'
                className='cursor'
              />
            )}
          </div>
          {subcribedPacks ? (
            _.isEmpty(subcribedPacks) ? (
              <MynyfyText title={'No Subscribed Packs'} />
            ) : (
              subcribedPacks?.map((res, i) => (
                <MynyfyRadioButton
                  key={i}
                  card
                  onClick={() => {
                    selectPackHandler(res);
                    setOpenSubcribedPacks(false);
                  }}
                  isSelected={res._id == selectedPack?._id}
                  LableComponent={() => (
                    <div>
                      <MynyfyText
                        title={
                          res.subscriptionId?.name ||
                          (res.subscriptionId?.type == CustomerSubscriptionType.PASS
                            ? theme.RUPEE + res.subscriptionId?.amount + ' Pass'
                            : res.subscriptionId?.count + ' Services/Items Subscription Pack')
                        }
                        bold
                      />
                      <MynyfyText
                        title={
                          'Remaining ' +
                          (res.subscriptionId?.type == CustomerSubscriptionType.PASS
                            ? `amount - ${
                                res.subscriptionId?.amount -
                                (res.discountAvailed || 0) -
                                (res.hold?.reduce((acc, curr) => acc + curr.amount, 0) || 0)
                              }`
                            : `Services/Items - ${
                                res.subscriptionId?.count -
                                (res.count || 0) -
                                (res.hold?.reduce((acc, curr) => acc + curr.count, 0) || 0)
                              }`)
                        }
                        style={{ marginTop: 5 }}
                      />
                      {res.subscriptionId?.type == CustomerSubscriptionType.PACK ? (
                        <MynyfyText title={'More Details'} pSmall link style={{ marginTop: 5 }} />
                      ) : null}
                    </div>
                  )}
                />
              ))
            )
          ) : (
            <MynyfyLoader paddingTop={20} />
          )}
        </div>
      </MynyfyModal>
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default ProductCart;
