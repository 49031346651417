import React, { useState } from 'react';
import MynyfyModal from './MynyfyModal';
import SwipeableViews from 'react-swipeable-views';
import { HighlightOffRounded } from '@mui/icons-material';

const ShowImages = ({ isVisible, closeModal, images }) => {
  const [page, setPage] = useState(0);

  const closeHandler = () => {
    closeModal();
    setPage(0);
  };

  return (
    <MynyfyModal
      open={isVisible}
      close={() => closeHandler()}
      style={{ backgroundColor: 'transparent' }}>
      <div id='ShowImages'>
        <HighlightOffRounded
          style={{ color: '#fff' }}
          onClick={() => closeHandler()}
          className='closeBtnContainer'
        />

        {images ? (
          <SwipeableViews
            axis={'x'}
            index={page}
            onChangeIndex={(index) => setPage(index)}
            enableMouseEvents
            className='tab'
            containerStyle={{ height: '100%' }}
            slideStyle={{ height: '100%' }}>
            {images?.map((res, i) => (
              <img key={i} src={res.url} className='carouselImg' />
            ))}
          </SwipeableViews>
        ) : null}

        <div className='imgIndicationContainer row'>
          {images?.map((res, i) => (
            <div key={i} onClick={() => setPage(i)}>
              <div className={`indicator ${i == page && 'activeIndicator'}`} />
            </div>
          ))}
        </div>
      </div>
    </MynyfyModal>
  );
};

export default ShowImages;
