import React, { Fragment, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import theme from '../../utils/theme';
import MynyfyHeader from '../../components/MynyfyHeader';
import { onShare } from '../Refer/referHelper';
import { Card } from '@mui/material';
import _ from 'lodash';
import MynyfySpan from '../../components/MynyfySpan';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { contestDetailsOptions } from '../Contest/ContestHelper';
import { InfoOutlined, KeyboardDoubleArrowRight, PlayCircleFilled } from '@mui/icons-material';
import MynyfyPre from '../../components/MynyfyPre';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SelectAdType from '../../components/SelectAdType';
import './home.scss';
import SignInModal from '../Auth/SignInModal';
import JackpotVideo from '../Contest/Jackpot/JackpotVideo';
import ReactPlayer from 'react-player';
import MynyfyModal from '../../components/MynyfyModal';

const DealsLayout = ({ noHeader }) => {
  const navigate = useNavigate();

  const mynts = useSelector((state) => state.mynts);
  const user = useSelector((state) => state.user);
  const profile = user.profile;
  const isLoggedIn = user?.isLoggedIn;
  const ads = useSelector((state) => state.contest?.seeAndWin);
  const jackpot = useSelector((state) => state.contest?.jackpot);
  const deals = useSelector((state) => state.promos?.dayDeals?.sorted);
  const dealVideo = useSelector((state) => state.dealVideo);

  const [openAdModal, setOpenAdModal] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [openEarnMyntsModal, setOpenEarnMyntsModal] = useState(false);
  const [openMyntsInfo, setOpenMyntsInfo] = useState(false);

  return (
    <Fragment>
      {noHeader ? null : <MynyfyHeader title={'Mynts | Deals | Jackpots | Ads'} />}
      <div id='Home'>
        {isLoggedIn && (
          <Fragment>
            <div className='rowSB' style={{ paddingInline: 20 }}>
              <div className='row cursor' onClick={() => navigate('/Transactions', { state: 1 })}>
                <img
                  src={require('../../assets/imgs/icons/coin.png')}
                  style={{ width: 40, height: 40 }}
                />
                <div style={{ marginLeft: 5 }}>
                  <MynyfyText title={mynts} className='myntVal' pBig />
                  <div className='row'>
                    <MynyfyText title={'My Mynts'} color={theme.MUTED_BLUE} pBig />
                  </div>
                </div>
              </div>
              <div>
                <InfoOutlined
                  className='cursor'
                  style={{ color: 'grey', display: 'block' }}
                  onClick={() => setOpenMyntsInfo(!openMyntsInfo)}
                />
              </div>
            </div>
            {openMyntsInfo ? (
              <div style={{ paddingTop: 10, marginLeft: 10 }}>
                <MynyfyText
                  title={
                    '* Mynts cannot be withdrawn as money. You may use them to purchase deals and join jackpots.'
                  }
                  color={theme.MUTED_BLUE}
                  pSmall
                />
              </div>
            ) : null}
            <div className='divider' style={{ margin: '15px 10px' }} />
          </Fragment>
        )}

        <div className='rowSB' style={{ padding: '0px 25px' }}>
          <MynyfyText
            title={'EARN MYNTS >>'}
            className='cursor'
            bold
            onClick={() => setOpenEarnMyntsModal(true)}
          />
          <MynyfyText
            title={'Refer & Earn'}
            className='cursor'
            pBig
            bold
            onClick={() => onShare(profile?.referralCode)}
          />
        </div>
        <div className='divider' style={{ margin: '15px 10px' }} />

        <div style={{ margin: 20 }}>
          <Card className='feature'>
            <div>
              <div className='rowSB'>
                <MynyfyText
                  title={'See Ads & Earn Mynts'}
                  pBig
                  bold
                  link
                  onClick={() => {
                    if (isLoggedIn) navigate('/Posts');
                    else setOpenSignIn(true);
                  }}
                />
                <div className='row cursor' onClick={() => setOpenAdModal(true)}>
                  <MynyfyText title={'Publish Ads'} style={{ paddingRight: 7 }} />
                  <AddCircleIcon color='primary' fontSize='small' />
                </div>
              </div>
              {ads ? (
                _.isEmpty(ads) ? (
                  <MynyfyEmptyMsg
                    message={'No Ads at your location'}
                    paddingTop={10}
                    small={true}
                  />
                ) : (
                  <div className='offer'>
                    {ads?.slice(0, 4)?.map((ads, i) => (
                      <div
                        onClick={() => {
                          if (isLoggedIn) navigate('/Posts');
                          else setOpenSignIn(true);
                        }}
                        key={i}>
                        <Card style={{ width: 150, marginRight: 10 }} className='cursor'>
                          <img
                            src={ads.image}
                            alt={ads.name}
                            style={{ objectFit: 'cover', height: 150, width: 150 }}
                          />
                          <MynyfyText
                            style={{ padding: 10, textTransform: 'capitalize' }}
                            className='singleLine'
                            title={ads.name}
                            center
                          />
                        </Card>
                      </div>
                    ))}
                    <KeyboardDoubleArrowRight
                      onClick={() => {
                        if (isLoggedIn) navigate('/Posts');
                        else setOpenSignIn(true);
                      }}
                      className='all cursor'
                      style={{ marginTop: '80px' }}
                    />
                  </div>
                )
              ) : (
                <MynyfyLoader paddingTop={10} />
              )}
            </div>
          </Card>
        </div>

        <div className='divider' style={{ margin: '15px 10px' }} />
        <div className='row' style={{ paddingLeft: 25 }}>
          <MynyfyText title={'SPEND MYNTS '} bold />
          <MynyfyText
            title={'use mynts to buy deals & win jackpots.'}
            color={theme.MUTED_BLUE}
            pSmall
            style={{ marginLeft: 10 }}
          />
        </div>
        <div className='divider' style={{ margin: '15px 10px' }} />
        {dealVideo ? (
          <div>
            {_.isEmpty(deals) ? null : (
              <div
                className='rowSB'
                style={{ margin: '0px 25px 10px' }}
                onClick={() => navigate('/Deals')}>
                <MynyfyText title={'Buy Deals'} pBig bold link />
                <MynyfyText title={'View All >>'} link bold />
              </div>
            )}
            <div className='dealBannerContainer'>
              {dealVideo?.video ? (
                <div style={{ position: 'relative' }}>
                  <ReactPlayer
                    url={dealVideo.video}
                    config={{
                      youtube: { playerVars: { showinfo: 0, rel: 0, controls: 0 } },
                      vimeo: { playerOptions: { title: false } },
                    }}
                    width={'100%'}
                    height={260}
                    playing={videoPlay}
                    onEnded={() => setVideoPlay(false)}
                  />
                  {dealVideo.video?.includes('vimeo') && !videoPlay ? (
                    <PlayCircleFilled
                      onClick={() => setVideoPlay(!videoPlay)}
                      className='videoPlayBtn'
                    />
                  ) : null}
                </div>
              ) : (
                <img src={dealVideo.image} className='dealBannerImg' />
              )}
            </div>
          </div>
        ) : (
          <div className='featureContainer'>
            <Card className='feature'>
              <div>
                <div className='rowSB' onClick={() => navigate('/Deals')}>
                  <MynyfyText title={'Buy Deals'} pBig bold link />
                  <MynyfyText title={'View All >>'} link bold />
                </div>
                {deals ? (
                  _.isEmpty(deals) ? (
                    <MynyfyEmptyMsg
                      message={
                        'Deals will be published every Friday, Keep accumulating mynts until then.'
                      }
                      paddingTop={10}
                      small={true}
                    />
                  ) : (
                    <div className='offer'>
                      {deals?.slice(0, 4)?.map((dealDetails, i) => (
                        <div
                          onClick={() =>
                            navigate(`/Deals/${dealDetails._id}`, {
                              state: { details: dealDetails },
                            })
                          }
                          key={i}>
                          <Card style={{ width: 150, marginRight: 10 }} className='cursor'>
                            <img
                              src={dealDetails.image.url}
                              style={{ objectFit: 'cover', height: 150, width: 150 }}
                            />
                            <MynyfyText
                              title={dealDetails.name}
                              center
                              style={{ padding: 10, textTransform: 'capitalize' }}
                              className='singleLine'
                            />
                            <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                              <div>
                                <MynyfySpan
                                  title={theme.RUPEE + dealDetails.actualPrice}
                                  small
                                  style={{
                                    textDecoration: dealDetails.value ? 'line-through' : 'unset',
                                  }}
                                />
                                &nbsp;
                                {dealDetails.value ? (
                                  <MynyfySpan title={theme.RUPEE + dealDetails.value} small />
                                ) : null}
                              </div>
                              <div>
                                <div className='row'>
                                  <MynyfyPre title={`For: `} small />
                                  <MynyfyText
                                    title={dealDetails.points}
                                    style={{ marginRight: 3 }}
                                    small
                                  />
                                  <img
                                    src={require('../../assets/imgs/icons/coin.png')}
                                    alt='mynts'
                                    height={'10px'}
                                    width={'10px'}
                                  />
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                      <KeyboardDoubleArrowRight
                        onClick={() => navigate('/Deals')}
                        className='all cursor'
                        style={{ marginRight: '-25px', marginTop: '100px' }}
                      />
                    </div>
                  )
                ) : (
                  <MynyfyLoader paddingTop={10} />
                )}
              </div>
            </Card>
          </div>
        )}
        <div className='featureContainer' style={{ paddingBottom: 30 }}>
          <Card className='feature'>
            <div>
              <div className='rowSB' onClick={() => navigate('/Jackpots')}>
                <MynyfyText title={'Join Jackpot'} pBig bold link />
                <MynyfyText title={'View All >>'} link bold />
              </div>
              {jackpot ? (
                _.isEmpty(jackpot) ? (
                  <MynyfyEmptyMsg
                    message={'No Jackpots at your location'}
                    paddingTop={10}
                    small={true}
                  />
                ) : (
                  <div className='offer'>
                    {jackpot?.slice(0, 4)?.map((contestDetails, i) => (
                      <div
                        onClick={() => {
                          let options = contestDetailsOptions(contestDetails.type, false);
                          navigate('/Contest/' + contestDetails._id, {
                            state: { drawDetails: contestDetails, detailOptions: options },
                          });
                        }}
                        key={i}>
                        <Card className='cursor homeContestCard'>
                          {contestDetails.video ? (
                            <div onClick={(event) => event.stopPropagation()}>
                              <JackpotVideo contestDetails={contestDetails} />
                            </div>
                          ) : (
                            <img
                              src={contestDetails.image}
                              alt={contestDetails.name}
                              className='contestImage'
                            />
                          )}
                          <MynyfyText
                            title={contestDetails.name}
                            style={{ padding: '10px' }}
                            className='singleLine'
                            center
                          />
                          <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                            <div style={{ display: 'flex', marginTop: '5px' }}>
                              <img
                                src={require('../../assets/imgs/icons/coin.png')}
                                alt='mynts'
                                height={'10px'}
                                width={'10px'}
                              />
                              <MynyfyText
                                title={`${contestDetails.myntsToJoin} mynts`}
                                style={{ marginLeft: 5 }}
                                xSmall
                              />
                            </div>
                            <MynyfyText
                              title={contestDetails.isContested ? 'Joined' : 'Join'}
                              bold
                              center
                              small
                            />
                          </div>
                        </Card>
                      </div>
                    ))}
                    <KeyboardDoubleArrowRight
                      onClick={() => navigate('/Jackpots')}
                      className='all cursor'
                      style={{ marginTop: '100px' }}
                    />
                  </div>
                )
              ) : (
                <MynyfyLoader paddingTop={10} />
              )}
            </div>
          </Card>
        </div>
      </div>

      <MynyfyModal open={openEarnMyntsModal} close={() => setOpenEarnMyntsModal(false)}>
        <div>
          <MynyfyText title={'Earn Mynts'} bold center style={{ paddingBottom: 15 }} h6 />
          <MynyfyText title={'- For Seeing Ads'} />
          <MynyfyText title={'- For Shopping with any Seller'} style={{ padding: '7px 0px' }} />
          <MynyfyText title={'- Referring friends'} />
        </div>
      </MynyfyModal>

      <SelectAdType
        isVisible={openAdModal}
        closeModal={() => setOpenAdModal(false)}
        flashAdScreen={'/FlashAds/Create'}
        adScreen={'/PublishAds/Create'}
      />

      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </Fragment>
  );
};

export default DealsLayout;
