import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import productService from '../../services/productService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfySpan from '../../components/MynyfySpan';
import './products.scss';
import MynyfyFilter from '../../components/MynyfyFilter';
import { ProductMaterialType, ProductType, SortTypes } from '../../utils/enums';
import { storeTimingHandler } from '../../utils/CommonMethods';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import {
  AddRounded,
  ControlPointRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  RemoveCircleOutlineRounded,
  RemoveRounded,
  SearchOutlined,
} from '@mui/icons-material';
import { addToBag, decrementHandler, incrementHandler } from './AddProductHelper';
import theme from '../../utils/theme';
import ShowImages from '../../components/ShowImages';
import ShowDescription from '../../components/ShowDescription';

const Products = ({ from, brandDetails, sellerDetails, isEcommerceActive, table }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  const navFrom = from;

  const location = useSelector((state) => state.location?.location);
  const bag = useSelector((state) => state.bag);

  const isRestaurant = sellerDetails?.ownerId?.categories?.find(
    (e) => e._id == process.env.REACT_APP_FOOD_CAT_ID
  );

  const [products, setProducts] = useState(null);
  const [services, setServices] = useState(null);
  const [isProducts, setIsProducts] = useState(null);
  const [isServices, setIsServices] = useState(null);
  const [type, setType] = useState(ProductType.PRODUCT);
  const [actualProducts, setActualProducts] = useState(null);
  const [searchInput, setSearchInput] = useState(null);
  const [sectionProducts, setSectionProducts] = useState([]);
  const [openedSubCat, setOpenedSubCat] = useState([]);
  const [openImageList, setOpenImageList] = useState(false);
  const [imageList, setImageList] = useState(null);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [description, setDescription] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(sellerDetails || brandDetails) || route?.state?.from == 'shop') {
      let params = productsParamsHandler({});
      if (!_.isEmpty(params)) getProducts(params);
    } else {
      setProducts([]);
      setServices([]);
      setActualProducts([]);
    }
  }, []);

  const productsParamsHandler = ({ catId }) => {
    let brandId =
      (navFrom == 'seller' && sellerDetails?.brands) || (navFrom == 'brand' && brandDetails?._id);
    if (!_.isEmpty(brandId) || sellerDetails?._id || route?.state?.from == 'shop') {
      let data = {
        status: 'ACTIVE',
        brands: brandId || [],
      };
      if (catId) data.category = catId;
      if (searchInput) data.search = searchInput;
      if (sellerDetails) {
        data.seller = sellerDetails._id;
        if (sellerDetails.state) data.state = sellerDetails.state?._id;
        if (sellerDetails.city) data.city = sellerDetails.city?._id;
        if (sellerDetails.area) data.area = sellerDetails.area?._id;
        if (_.isEmpty(catId)) {
          let sellerCats = sellerDetails.ownerId.categories.filter((e) => e.code === 'Category');
          data.category = sellerCats.map((res) => res._id);
        }
        if (!_.isEmpty(sellerDetails.productInActiveSubCategories)) {
          data.notRequiredSubCats = sellerDetails.productInActiveSubCategories;
        }
      }
      if (route?.state?.from == 'shop') {
        if (location) {
          if (location?.state) data.state = location?.state?._id;
          if (location?.city) data.city = location?.city?._id;
          if (location?.area) data.area = location?.area?._id;
        }
      }
      return data;
    } else {
      setProducts([]);
      setServices([]);
      setActualProducts([]);
    }
  };

  const getProducts = (params) => {
    // FIXME pagination api for products
    // if (from == 'seller' || from == 'brand') {
    //   let body = { ...params, limit: PAGE_LIMIT, page: 0 };
    //   productService.getProductsWithPagnation(body).then((res) => {
    //     productsApiResponse(res);
    //   });
    // } else {
    // }
    productService.getProducts(params).then((res) => {
      productsApiResponse(res);
    });
  };

  const productsApiResponse = (res) => {
    if (res.success) {
      let sorted = _.orderBy(res.data, 'createdAt', 'desc');
      let sortedProducts = sorted.filter((e) => e.type == ProductType.PRODUCT);
      let sortedServices = sorted.filter((e) => e.type == ProductType.SERVICE);
      productSectionHandler(_.isEmpty(sortedProducts) ? sortedServices : sortedProducts);
      setProducts(sortedProducts);
      setServices(sortedServices);
      setIsProducts(_.isEmpty(sortedProducts) ? false : true);
      setIsServices(_.isEmpty(sortedServices) ? false : true);
      setActualProducts(sorted);
      if (_.isEmpty(sortedProducts)) setType(ProductType.SERVICE);
    } else {
      dispatch(snackbarInfo({ type: 'error', open: true, message: res.message }));
    }
  };

  const searchAndCatFilter = ({
    searchedText,
    selectedCategory,
    selectedSubCategory,
    selectedSort,
    selectedFoodType,
    sortedData,
  }) => {
    let reqData = sortedData
      ? sortedData
      : actualProducts.filter((e) => e.type == (type || ProductType.PRODUCT));
    if (!_.isEmpty(reqData)) {
      let newData = [];
      if (selectedFoodType == SortTypes.ONLY_VEG) {
        newData = reqData.filter((e) => e.materialType == ProductMaterialType.VEGETARIAN);
      } else if (selectedFoodType == SortTypes.ONLY_NON_VEG) {
        newData = reqData.filter((e) => e.materialType == ProductMaterialType.NON_VEGETARIAN);
      } else if (selectedFoodType == SortTypes.ALL) {
        newData = reqData;
      } else newData = reqData;
      if (selectedCategory) {
        newData = newData.filter((e) =>
          e.category ? e.category._id === selectedCategory?.value : e.category === undefined
        );
      }
      if (selectedSubCategory) {
        newData = newData.filter((e) =>
          e.subCategory
            ? e.subCategory._id === selectedSubCategory?.value
            : e.subCategory === undefined
        );
      }
      let modData = [];
      if (searchedText) {
        newData.map((res) => {
          if (res.name.toLowerCase().includes(searchedText.toLowerCase())) {
            modData.push(res);
          }
        });
      } else modData = newData;

      if (selectedSort) {
        sortFilter(selectedSort, modData);
      } else {
        productSectionHandler(modData);
        if (type == ProductType.PRODUCT) setProducts(modData);
        else setServices(modData);
      }
    }
  };

  const sortFilter = (e, sortedData) => {
    let reqData = sortedData ? sortedData : type == ProductType.PRODUCT ? products : services;
    if (!_.isEmpty(reqData)) {
      let sorted = null;
      if (e == SortTypes.LOW_TO_HIGH_PRICE) {
        sorted = _.orderBy(reqData, 'price', 'asc');
      } else if (e == SortTypes.HIGH_TO_LOW_PRICE) {
        sorted = _.orderBy(reqData, 'price', 'desc');
      }
      if (sorted) {
        productSectionHandler(sorted);
        if (type == ProductType.PRODUCT) setProducts(sorted);
        else setServices(sorted);
      }
    }
  };

  const productNavigationHandler = (item) => {
    let storeStatus = sellerDetails ? storeTimingHandler(sellerDetails) : true;
    if (storeStatus) {
      navigate(`/Product/${item._id}`, {
        state: { details: item, navFrom, brandDetails, sellerDetails, isEcommerceActive, table },
      });
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'warning',
          message: `Currently store is closed, Please come back at ${sellerDetails.storeOpenTime}`,
        })
      );
    }
  };

  const productSectionHandler = (productsData) => {
    let sectionData = [];
    productsData.map((e) => {
      if (_.isEmpty(sectionData)) {
        sectionData.push({
          title: e.subCategory?.name || 'Others',
          id: e.subCategory?._id || null,
          data: [e],
        });
      } else {
        let reqIndex = sectionData.findIndex((f) => f.title == (e.subCategory?.name || 'Others'));
        if (reqIndex > -1) {
          sectionData[reqIndex].data.push(e);
        } else {
          sectionData.push({
            title: e.subCategory?.name || 'Others',
            id: e.subCategory?._id || null,
            data: [e],
          });
        }
      }
    });
    let sorted = _.orderBy(sectionData, 'title', 'asc');
    const othersIndex = sorted.findIndex((e) => e.title === 'Others');
    if (othersIndex !== -1) {
      const othersItem = sorted.splice(othersIndex, 1)[0];
      sorted.push(othersItem);
    }

    if (_.isEmpty(sellerDetails?.productSubCatSequence)) {
      setSectionProducts(sorted);
    } else {
      let arrangedData = sellerDetails?.productSubCatSequence
        .map((e) => sorted.find((item) => item.id === e))
        .filter(Boolean);

      let remainingData = sorted.filter(
        (item) => !sellerDetails?.productSubCatSequence.includes(item.id)
      );

      let result = arrangedData.concat(remainingData);
      setSectionProducts(result);
    }
  };

  const addToBagHandler = (item) => {
    if (isEcommerceActive) {
      let storeStatus = sellerDetails ? storeTimingHandler(sellerDetails) : true;
      if (storeStatus) {
        if (!_.isEmpty(item.size) || !_.isEmpty(item.color)) {
          productNavigationHandler(item);
        } else {
          addToBag({ productDetails: item, bag, dispatch });
        }
      } else {
        dispatch(
          snackbarInfo({
            open: true,
            type: 'warning',
            message: `Currently store is closed, Please come back at ${sellerDetails.storeOpenTime}`,
          })
        );
      }
    } else {
      dispatch(
        snackbarInfo({
          open: true,
          type: 'error',
          message: 'Currently not in service please contact store',
        })
      );
    }
  };

  const subCatOpenHandler = (key) => {
    let clonedData = _.cloneDeep(openedSubCat);
    if (clonedData.includes(key)) {
      clonedData = clonedData.filter((e) => e !== key);
    } else {
      clonedData.push(key);
    }
    setOpenedSubCat(clonedData);
  };

  const descriptionHandler = (item) => {
    setDescription({ title: item.name, description: item.description });
    setOpenDescriptionModal(true);
  };

  return actualProducts ? (
    <Fragment>
      <div id='Products' className='rowSB' style={{ padding: '0px 15px 10px' }}>
        <div className='row' style={{ paddingTop: 10 }}>
          <MynyfyText
            title={'OUR' + (isProducts || isServices ? '' : isRestaurant ? ' MENU' : ' PRODUCTS')}
            h6
            bold
          />
          {isProducts ? (
            <MynyfyText
              title={isRestaurant ? 'Menu' : 'Products'}
              className='itemTypeBtn cursor'
              active={type == ProductType.PRODUCT ? 'true' : 'false'}
              pSmall
              bold
              onClick={() => {
                setType(ProductType.PRODUCT);
                productSectionHandler(products);
              }}
            />
          ) : null}
          {isServices ? (
            <MynyfyText
              title={'Services'}
              className='itemTypeBtn cursor'
              active={type == ProductType.SERVICE ? 'true' : 'false'}
              pSmall
              bold
              onClick={() => {
                setType(ProductType.SERVICE);
                productSectionHandler(services);
              }}
            />
          ) : null}
        </div>
        <SearchOutlined onClick={() => setIsFilterVisible(!isFilterVisible)} className='cursor' />
      </div>
      {isFilterVisible ? (
        <MynyfyFilter
          searchAndCatFilter={(e) => searchAndCatFilter(e)}
          sortFilter={(e) => sortFilter(e)}
          isPrice
          isFood={isRestaurant ? true : false}
          style={{ borderTop: '1px solid #e6e6e6' }}
          userCategories={sellerDetails?.ownerId?.categories}
        />
      ) : null}
      {_.isEmpty(sectionProducts) ? (
        <MynyfyEmptyMsg
          paddingTop={'18vh'}
          message={`No ${(type || 'Product') + 's'} are available`}
        />
      ) : (
        <Fragment>
          <div id='Products'>
            {sectionProducts?.map((res, j) => (
              <Fragment key={res.title + j}>
                <div
                  className='catSection rowSB cursor'
                  onClick={() => subCatOpenHandler(res.title)}>
                  <div>
                    <MynyfySpan title={res.title} bold />
                    <MynyfySpan title={' (' + (res.data?.length || 0) + ')'} bold />
                  </div>
                  {openedSubCat?.includes(res.title) ? (
                    <KeyboardArrowDownRounded />
                  ) : (
                    <KeyboardArrowUpRounded />
                  )}
                </div>
                {!openedSubCat?.includes(res.title) ? (
                  isRestaurant ? (
                    res.data.map((item, i) => (
                      <div
                        className='foodProductContainer'
                        key={i}
                        menu-img={table && !sellerDetails?.menuImages ? 'no' : 'yes'}>
                        <div
                          className='foodProductSubContainer'
                          menu-img={table && !sellerDetails?.menuImages ? 'no' : 'yes'}>
                          <div className='foodProductLeftContainer'>
                            <div>
                              <div className='row' style={{ alignItems: 'flex-start' }}>
                                {table && !sellerDetails?.menuImages && item.materialType ? (
                                  <div style={{ margin: '5px 8px -4px 0px' }}>
                                    {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                                      <NonVegIcon width={15} height={15} />
                                    ) : (
                                      <VegIcon width={15} height={15} />
                                    )}
                                  </div>
                                ) : null}
                                <MynyfyText title={item.name} bold h5 />
                              </div>
                              <div
                                className='row'
                                style={{
                                  margin:
                                    table && !sellerDetails?.menuImages
                                      ? '10px 0px 0px'
                                      : '5px 0px',
                                }}>
                                <MynyfyText
                                  title={theme.RUPEE + item.actualPrice}
                                  style={{
                                    textDecorationLine:
                                      item.price && item.actualPrice !== item.price
                                        ? 'line-through'
                                        : 'unset',
                                  }}
                                  small={
                                    item.price && item.actualPrice !== item.price ? true : false
                                  }
                                  bold={
                                    item.price && item.actualPrice !== item.price ? false : true
                                  }
                                />
                                {item.actualPrice == item.price || !item.price ? null : (
                                  <MynyfyText
                                    title={theme.RUPEE + item.price}
                                    style={{ margin: '0px 5px' }}
                                    bold
                                  />
                                )}
                              </div>
                              {table && !sellerDetails?.menuImages ? null : item.description ? (
                                <Fragment>
                                  <MynyfyText
                                    id={item._id}
                                    title={item.description}
                                    className='foodPoductDescription'
                                    onClick={() => descriptionHandler(item)}
                                  />
                                  <MynyfyText
                                    id={item._id}
                                    title={'More details'}
                                    link
                                    small
                                    onClick={() => descriptionHandler(item)}
                                  />
                                </Fragment>
                              ) : null}
                            </div>

                            {table && !sellerDetails?.menuImages ? null : item.materialType ? (
                              <div style={{ marginBottom: -20, marginTop: 5 }}>
                                {item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                                  <NonVegIcon width={20} height={20} />
                                ) : (
                                  <VegIcon width={20} height={20} />
                                )}
                              </div>
                            ) : null}
                          </div>
                          <div style={{ flex: 1, textAlign: 'end' }}>
                            <div style={{ position: 'relative' }}>
                              {table && !sellerDetails?.menuImages ? null : (
                                <div
                                  onClick={() => {
                                    setImageList(item.image);
                                    setOpenImageList(true);
                                  }}>
                                  <img src={item.image[0].url} className='foodProductImg' />
                                </div>
                              )}
                              {bag?.products?.find((e) => e.productId == item._id) ? (
                                <div
                                  className='rowSB addProductConatiner'
                                  style={{ padding: '8.5px 10px' }}>
                                  <div
                                    onClick={() => {
                                      decrementHandler({ productDetails: item, bag, dispatch });
                                    }}>
                                    <RemoveRounded fontSize='small' className='productIcon link' />
                                  </div>
                                  <MynyfyText
                                    title={
                                      bag?.products?.find((e) => e.productId == item._id)?.count ||
                                      0
                                    }
                                    h6
                                    bold
                                    center
                                    style={{ userSelect: 'none', cursor: 'default' }}
                                  />
                                  <div
                                    onClick={() => {
                                      incrementHandler({ productDetails: item, bag, dispatch });
                                    }}>
                                    <AddRounded fontSize='small' className='productIcon link' />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className='addProductConatiner link'
                                  style={{
                                    backgroundColor: item.count
                                      ? item.count - item.ordersCount > 0
                                        ? theme.LIGHT_COLOR
                                        : theme.MUTED_COLOR
                                      : theme.LIGHT_COLOR,
                                  }}
                                  onClick={() => {
                                    if (
                                      item.count
                                        ? item.count - item.ordersCount > 0
                                          ? false
                                          : true
                                        : false
                                    ) {
                                      // it is disabled
                                    } else {
                                      addToBagHandler(item);
                                    }
                                  }}>
                                  {(
                                    item.count
                                      ? item.count - item.ordersCount > 0
                                        ? false
                                        : true
                                      : false
                                  ) ? (
                                    <MynyfyText
                                      title={'Out Of Stock'}
                                      small
                                      bold
                                      center
                                      color={theme.ERROR}
                                    />
                                  ) : (
                                    <MynyfyText
                                      title={route?.state?.from == 'shop' ? 'Shop' : 'Add'}
                                      primary
                                      bold
                                      center
                                      style={{ textTransform: 'uppercase' }}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='productsMainContainer' style={{ paddingTop: 10 }}>
                      {res.data.map((item, i) => (
                        <div
                          key={i}
                          className='productContainer cursor'
                          style={{ marginTop: i > 1 ? 30 : 5 }}
                          onClick={() => productNavigationHandler(item)}>
                          <div className='productImgContainer' style={{ position: 'relative' }}>
                            <img src={item.image[0].url} alt={item.name} className='productImg' />
                            <div
                              className='addToBagContainerInList'
                              onClick={(event) => event.stopPropagation()}>
                              {bag?.products?.find((e) => e.productId == item._id) ? (
                                <div className='rowC' style={{ padding: 10 }}>
                                  <RemoveCircleOutlineRounded
                                    className='cursor'
                                    htmlColor='#fff'
                                    onClick={() => {
                                      decrementHandler({ productDetails: item, bag, dispatch });
                                    }}
                                  />
                                  <MynyfyText
                                    title={
                                      bag?.products?.find((e) => e.productId == item._id)?.count ||
                                      0
                                    }
                                    className='quantityContainer'
                                    h6
                                    color='#fff'
                                  />
                                  <ControlPointRounded
                                    className='cursor'
                                    htmlColor='#fff'
                                    onClick={() => {
                                      incrementHandler({ productDetails: item, bag, dispatch });
                                    }}
                                  />
                                </div>
                              ) : (
                                <MynyfyText
                                  title={'Add to Bag'}
                                  className='cursor'
                                  color='#fff'
                                  center
                                  style={{ padding: 10 }}
                                  onClick={() => addToBagHandler(item)}
                                />
                              )}
                            </div>
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            <MynyfyText title={item.name} h6 color={'#000000'} />
                            <MynyfyText title={item.description} className='poductDescription' />
                            <div className='rowSB' style={{ paddingTop: 5 }}>
                              <div>
                                <MynyfySpan
                                  title={'Rs.' + item.actualPrice}
                                  style={{
                                    textDecoration:
                                      item.price && item.actualPrice !== item.price
                                        ? 'line-through'
                                        : 'unset',
                                  }}
                                  bold={
                                    item.price && item.actualPrice !== item.price ? false : true
                                  }
                                />
                                {item.actualPrice == item.price || !item.price ? null : (
                                  <MynyfySpan
                                    title={'Rs.' + item.price}
                                    bold
                                    style={{ paddingLeft: 5 }}
                                  />
                                )}
                              </div>

                              {item.materialType ? (
                                item.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                                  <NonVegIcon width={20} height={20} />
                                ) : (
                                  <VegIcon width={20} height={20} />
                                )
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                ) : null}
              </Fragment>
            ))}
          </div>
          <ShowImages
            isVisible={openImageList}
            closeModal={() => {
              setOpenImageList(false);
              setImageList(null);
            }}
            images={imageList}
          />
          <ShowDescription
            isVisible={openDescriptionModal}
            closeModal={() => {
              setOpenDescriptionModal(false);
              setDescription(null);
            }}
            data={description}
          />
        </Fragment>
      )}
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default Products;
