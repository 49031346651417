import React, { useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import theme from '../../utils/theme';
import AdItemFooter from './AdItemFooter';
import AdItemHeader from './AdItemHeader';
import ShowImages from '../../components/ShowImages';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { CircleRounded } from '@mui/icons-material';
import { getOS } from '../../utils/CommonMethods';

const AdItem = ({ item, currPosition, itemIndex, handleIntrest }) => {
  const dispatch = useDispatch();
  const platform = getOS();
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);

  // const urlToObject = async (url) => {
  //   const response = await fetch(url);
  //   const blob = await response.blob();
  //   const file = new File([blob], 'image.jpg', { type: blob.type });
  //   return file;
  // };

  const onShare = async () => {
    try {
      // await toPng(document.getElementById('post')).then(async (dataUrl) => {
      //   let aEl = document.createElement('a');
      //   aEl.href = dataUrl;
      //   document.body.appendChild(aEl);
      //   const filesGetter = await urlToObject(aEl);
      // });
      let body = {
        title: item.name,
        text:
          item.terms
            ?.split('$')
            .map((res) => '- ' + res)
            .join('\n') || '',
        // files: [filesGetter],
      };
      if (navigator.share) {
        await navigator.share(body);
      }
    } catch (error) {
      dispatch(
        snackbarInfo({ open: true, type: 'error', message: 'Share option is not available' })
      );
    }
  };

  return (
    <div className='page'>
      <AdItemHeader item={item} currPosition={currPosition} itemIndex={itemIndex} />
      <div className='post' id='post' style={{ backgroundColor: theme.LIGHT_COLOR }}>
        <div
          onClick={() => {
            if (platform == 'android' || platform == 'ios') {
              setIsImageModalVisible(true);
            }
          }}>
          <img src={item.image} className='drawImg' />
        </div>
        <AdItemFooter item={item} onShare={onShare} handleInterest={handleIntrest} />
        <div className='divider' />
        <div className='rowSB text'>
          <MynyfyText title={item.name} bold h6 />
          <div className='row' style={{ paddingRight: 2 }}>
            <MynyfyText title={'views:'} />
            &nbsp;
            <MynyfyText title={item.totalViews || 0} bold />
          </div>
        </div>
        <div
          className='descriptionHolder'
          os={platform == 'android' || platform == 'ios' ? 'mobile' : 'pc'}>
          <div className='text'>
            {item.terms?.split('$').map((res, i) => (
              <div className='row' style={{ marginTop: 10, alignItems: 'flex-start' }} key={i}>
                <CircleRounded className='dotIcon' />
                <MynyfyText title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ShowImages
        isVisible={isImageModalVisible}
        closeModal={() => setIsImageModalVisible(false)}
        images={[{ url: item?.image }]}
      />
    </div>
  );
};

export default AdItem;
