import React, { Fragment, useEffect, useState } from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import { useLocation, useNavigate } from 'react-router';
import MynyfyText from '../../components/MynyfyText';
import MynyfyButton from '../../components/MynyfyButton';
import { Carousel } from 'react-responsive-carousel';
import { Stack, Button, CardContent } from '@mui/material';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MynyfyLoader from '../../components/MynfyfLoader';
import _ from 'lodash';
import theme from '../../utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { fetchBagCalculation } from '../../redux/actions/bag.action';
import ProductCartHelper from './ProductCartHelper';
import MynyfyPre from '../../components/MynyfyPre';
import {
  ControlPointRounded,
  DiscountRounded,
  RemoveCircleOutlineRounded,
} from '@mui/icons-material';
import { ReactComponent as VegIcon } from '../../assets/svg/veg.svg';
import { ReactComponent as NonVegIcon } from '../../assets/svg/nonVeg.svg';
import { ProductMaterialType } from '../../utils/enums';
import { addToBag, decrementHandler, incrementHandler } from './AddProductHelper';

const NO_OF_OFFERS = 3;

const ProductsDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation();
  let productDetails = route.state?.details;
  let policies = route?.state?.sellerDetails?.policies || route?.state?.sellerDetails?.policies;

  const bag = useSelector((state) => state.bag);

  const [selectedColor, setColor] = useState(null);
  const [selectedSize, setSize] = useState(null);
  const [isMoreOffers, setMoreOffers] = useState(false);
  const [offers, setOffers] = useState(false);

  useEffect(() => {
    let isProductAdded = bag?.products?.find((e) => e.productId == productDetails._id);
    if (isProductAdded) {
      if (isProductAdded.size) setSize(isProductAdded.size);
      if (isProductAdded.color) setColor(isProductAdded.color);
    }

    if (productDetails?.category) {
      if (!_.isEmpty(bag?.offers)) {
        let reqOffers = bag?.offers?.filter(
          (e) => e?.category?._id == productDetails?.category?._id || !e?.category
        );
        setOffers(reqOffers);
      }
    } else {
      setOffers(bag?.offers);
    }
  }, []);

  const shareUrl = async () => {
    if (bag?.about) {
      try {
        if (navigator.share) {
          await navigator.share({
            title: bag?.about?.name,
            text:
              `Now you can find all the products, offer, contest of ${bag?.about?.name} ${bag?.about?.type}` +
              '\n' +
              '\n' +
              `${bag?.about?.type} link : ${bag?.about?.url}`,
          });
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const totalCalculation = (reqObj) => {
    if (!_.isEmpty(reqObj)) {
      let qty = 0;
      let amount = 0;
      reqObj.map((res) => {
        qty = qty + res.count;
        amount = amount + res.count * res.price;
      });
      if (qty > 0) {
        dispatch(fetchBagCalculation({ qty, amount }));
      } else {
        dispatch(fetchBagCalculation(null));
      }
    } else {
      dispatch(fetchBagCalculation(null));
    }
  };

  return productDetails ? (
    <Fragment>
      <MynyfyHeader
        title={'Product Details'}
        customElememt={() => (
          <ProductCartHelper
            sellerDetails={route.state?.sellerDetails}
            brandDetails={route.state?.brandDetails}
            navFrom={route.state?.navFrom}
            table={route?.state?.table}
          />
        )}
      />
      <div id='Products'>
        <div style={{ marginTop: 10 }}>
          <div style={{ display: 'flex' }}>
            <div sx={{ maxWidth: 200 }}>
              <div style={{ width: '100%', margin: 'auto' }}>
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  showStatus={false}
                  showArrows={false}
                  showThumbs={false}
                  interval={5000}>
                  {productDetails?.image?.map((res, i) => (
                    <div key={i}>
                      <img
                        src={res.url}
                        alt={productDetails.name}
                        style={{ objectFit: 'contain', height: 370 }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <CardContent>
                <MynyfyText title={productDetails.name} h6 color={'#000000'} bold />
                <MynyfyText title={productDetails.description} style={{ marginTop: 1 }} h6 />
                <div className='rowSB'>
                  <div>
                    <MynyfyText
                      title={'Price Rs.' + productDetails.actualPrice}
                      style={{
                        marginTop: 10,
                        textDecoration:
                          productDetails.price &&
                          productDetails.actualPrice !== productDetails.price
                            ? 'line-through'
                            : 'unset',
                      }}
                      bold={
                        productDetails.price && productDetails.actualPrice !== productDetails.price
                          ? false
                          : true
                      }
                    />
                    {productDetails.actualPrice == productDetails.price ||
                    !productDetails.price ? null : (
                      <MynyfyText title={'Offer Price Rs.' + productDetails.price} bold />
                    )}
                  </div>
                  {productDetails.materialType ? (
                    productDetails.materialType == ProductMaterialType.NON_VEGETARIAN ? (
                      <NonVegIcon width={20} height={20} />
                    ) : (
                      <VegIcon width={20} height={20} />
                    )
                  ) : null}
                </div>
              </CardContent>
            </div>
          </div>
        </div>
        {!_.isEmpty(productDetails.color) ? (
          <div>
            <div style={{ background: '#F1F1F1', height: '5px' }}></div>
            <div style={{ paddingTop: '10px', marginLeft: '16px' }}>
              <MynyfyText title='Color' pBig bold />
              <div className='row' style={{ overflowX: 'scroll', alignItems: 'flex-end' }}>
                {productDetails.color?.map((res, i) => (
                  <div key={i} style={{ textAlign: 'center' }}>
                    {/* <MynyfyText
                      style={{ paddingTop: 5, textTransform: 'capitalize' }}
                      title={res.includes('#') ? Convert.hex.keyword(res) : res}
                      center
                      primary={selectedColor === res ? true : false}
                    /> */}
                    <Button
                      style={{
                        backgroundColor: res,
                        border: selectedColor === res ? `5px solid ${theme.PRIMARY_BLUE}` : 0,
                        padding: selectedColor === res ? 28 : 30,
                      }}
                      className='productColor'
                      onClick={() => setColor(res)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {!_.isEmpty(productDetails.size) ? (
          <div>
            <div style={{ background: '#F1F1F1', height: '5px' }}></div>
            <div style={{ paddingTop: '10px', margin: '0px 16px' }}>
              <div className='rowSB'>
                <MynyfyText title='Select Size' pBig bold />
                {/* <MynyfyText title='Size Chart' link /> */}
              </div>
              <div style={{ marginTop: '10px' }}>
                <Stack spacing={1} direction='row'>
                  {productDetails.size?.map((res, i) => (
                    <Button
                      key={i}
                      variant={selectedSize === res ? 'contained' : 'outlined'}
                      onClick={() => setSize(res)}>
                      {res}
                    </Button>
                  ))}
                </Stack>
              </div>
              <br />
            </div>
          </div>
        ) : null}
        <div>
          {policies?.shipping ? (
            <div>
              <div style={{ background: '#F1F1F1', height: '5px' }}></div>
              <div style={{ paddingTop: '15px' }}>
                <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                  <MynyfyText title='Delivery Details' pBig bold />
                  <MynyfyText
                    title='Check Details'
                    link
                    onClick={() => {
                      navigate(`/Policies/Delivery_Policy`, {
                        state: {
                          policy: [
                            {
                              heading: null,
                              content: ['Delivery timeline will be shown in order screen'],
                            },
                          ],
                          policy2: policies?.shipping,
                          policyName: `Delivery Policy`,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <br />
          {_.isEmpty(offers) ? null : (
            <div>
              <div style={{ background: '#F1F1F1', height: '5px' }}></div>
              <div style={{ paddingTop: '15px' }}>
                <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                  <div className='rowSB'>
                    <MynyfyText title=' Offers' pBig bold />
                    {isMoreOffers ? (
                      <MynyfyText
                        title={`less offers`}
                        link
                        onClick={() => setMoreOffers(!isMoreOffers)}
                      />
                    ) : offers?.length - NO_OF_OFFERS > 0 ? (
                      <MynyfyText
                        title={`+${offers?.length - NO_OF_OFFERS} More offers`}
                        link
                        onClick={() => setMoreOffers(!isMoreOffers)}
                      />
                    ) : null}
                  </div>
                  {offers?.slice(0, isMoreOffers ? offers?.length : NO_OF_OFFERS)?.map((res, i) => (
                    <div style={{ display: 'flex', marginTop: 5 }} key={i}>
                      <DiscountRounded style={{ padding: 5 }} />
                      <div style={{ padding: '5px 8px 8px' }}>
                        <MynyfyText
                          style={{ paddingTop: 0 }}
                          title={res.name + (res.description ? ' - ' + res.description : '')}
                          className='poductOffersDescription'
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </div>
          )}
          {productDetails?.specification ? (
            <div>
              <div style={{ background: '#F1F1F1', height: '5px' }}></div>
              <div style={{ paddingTop: '15px' }}>
                <div style={{ margin: '0px 16px', marginTop: '5px' }}>
                  <div className='rowSB'>
                    <MynyfyText title='Product Details' pBig bold />
                    <MynyfyText title='+ More ' link />
                  </div>
                  <div style={{ paddingTop: 10 }}>
                    <MynyfyPre title={productDetails?.specification} color={'#000'} />
                  </div>
                </div>
              </div>
              <br />
            </div>
          ) : null}
          {policies?.isReturn || policies?.isExchange ? (
            <div>
              <div style={{ background: '#F1F1F1', height: '5px' }}></div>
              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <div className='rowSB' style={{ margin: '0px 16px', marginTop: '5px' }}>
                  <MynyfyText
                    title='Return/Refund/Exchange Details'
                    style={{ marginRight: 10 }}
                    pBig
                    bold
                  />
                  <MynyfyText
                    title='Check Details'
                    link
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => {
                      navigate(`/Policies/Return_Refund_Exchange_Policy`, {
                        state: {
                          policy: policies?.refund,
                          policy2: policies?.exchange,
                          policyName: `Return / Refund / Exchange Policy`,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div style={{ background: '#F1F1F1', height: '5px' }}></div>
        </div>
        <div className='row' style={{ padding: '10px 20px 15px' }}>
          <Button
            style={{ background: '#C5C5C5', padding: '15px', borderRadius: '10px' }}
            onClick={() => shareUrl()}>
            <IosShareOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
          </Button>
          {/* <div style={{ margin: '0px 10px 0px 15px' }}>
            <Button style={{ background: '#C5C5C5', padding: '15px', borderRadius: '10px' }}>
              <FavoriteBorderOutlinedIcon style={{ color: 'black', fontSize: '20px' }} />
            </Button>
          </div> */}
          {bag?.products?.find((e) => e.productId == productDetails._id) ? (
            <div className='rowC' style={{ margin: '0px 10px', flex: 1 }}>
              <RemoveCircleOutlineRounded
                color='primary'
                fontSize='medium'
                className='cursor'
                onClick={() => decrementHandler({ productDetails, bag, dispatch })}
              />
              <MynyfyText
                title={bag.products.find((e) => e.productId == productDetails._id)?.count}
                className='quantityContainer'
                h6
              />
              <ControlPointRounded
                color='primary'
                fontSize='medium'
                className='cursor'
                onClick={() => incrementHandler({ productDetails, bag, dispatch })}
              />
            </div>
          ) : (
            <div style={{ flex: 1, marginLeft: 10 }}>
              <MynyfyButton
                label={'Add to Bag'}
                fullWidth
                className='addBagBtn'
                onClick={() => {
                  if (route.state?.isEcommerceActive) {
                    addToBag({ productDetails, selectedSize, selectedColor, bag, dispatch });
                  } else {
                    dispatch(
                      snackbarInfo({
                        open: true,
                        type: 'error',
                        message: 'Currently not in service please contact store',
                      })
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  ) : (
    <MynyfyLoader />
  );
};

export default ProductsDetails;
