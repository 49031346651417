import React, { Fragment } from 'react';
import MynyfyModal from './MynyfyModal';
import { ContentCopyRounded, DownloadRounded } from '@mui/icons-material';
import MynyfyText from './MynyfyText';
import { useDispatch } from 'react-redux';
import { snackbarInfo } from '../redux/actions/snackbar.action';
import MynyfyUploadImg from './MynyfyUploadImg';

const ShowSellerQr = ({ isVisible, closeModal, upiId, upiQr, name, submitOrder }) => {
  const dispatch = useDispatch();

  const downloadImage = async () => {
    try {
      const response = await fetch(upiQr);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name || 'upi qr' + '.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadHandler = (event) => {
    if (event.imgFile.size / 1000 <= 1024) {
      event.purposeType = 'Order Payment';
      submitOrder(event);
      closeModal();
    } else {
      dispatch(
        snackbarInfo({ open: true, type: 'warning', message: 'File size must not exceed 4MB' })
      );
    }
  };

  return (
    <MynyfyModal open={isVisible} close={() => closeModal()}>
      <div style={{ maxHeight: '75%' }}>
        <div id='ShowSellerQR'>
          <MynyfyText title={'Make a payment'} bold center />
          <MynyfyText
            title={'Pay to UPI Id or UPI QR code from your UPI App and complete the order'}
            center
            pSmall
            style={{ padding: '5px 0px 20px' }}
          />
          <div className='rowSB upiIdContainer'>
            <MynyfyText title={upiId} />
            <ContentCopyRounded
              color='primary'
              className='cursor'
              onClick={() => {
                navigator.clipboard.writeText(upiId);
                dispatch(
                  snackbarInfo({ open: true, type: 'success', message: 'UPI Id is Copied' })
                );
              }}
            />
          </div>
          {/* {upiQr ? (
            <Fragment>
              <MynyfyText title={'OR'} center style={{ margin: '10px 0px' }} />
              <div style={{ textAlign: 'center' }}>
                <div style={{ position: 'relative', width: 205, height: 205, margin: 'auto' }}>
                  <img
                    src={upiQr}
                    style={{ width: 200, height: 200, objectFit: 'contain', cursor: 'pointer' }}
                    onClick={() => window.open(upiQr)}
                  />
                  <DownloadRounded
                    color='primary'
                    style={{ cursor: 'pointer', position: 'absolute', right: -10, bottom: 0 }}
                    onClick={() => downloadImage()}
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
          <MynyfyText title='Upload UPI payment screenshot' center style={{ paddingTop: 15 }} /> */}
          <MynyfyUploadImg
            accept={true}
            type='button'
            label={'Upload Payment Image'}
            name='uploadPaymentImage'
            containerMargin='8px 4px 10px'
            onChange={(e) => uploadHandler(e)}
          />
        </div>
      </div>
    </MynyfyModal>
  );
};

export default ShowSellerQr;
