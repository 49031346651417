import React, { Fragment, useState } from 'react';
import '../contest.scss';
import theme from '../../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { ContestType } from '../../../utils/enums';
import MynyfyText from '../../../components/MynyfyText';
import { GroupsRounded } from '@mui/icons-material';
import { ReactComponent as ScanAndWinIcon } from '../../../assets/svg/scan_and_win.svg';
import JoinScanAndWinByVoucher from './JoinScanAndWinByVoucher';
import { useSelector } from 'react-redux';
import SignInModal from '../../Auth/SignInModal';

const ScanAndWinFooter = ({ item, isSingle, isHistory, isRefer, onDetailsClick }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);

  const [isJoinDrawModal, setIsJoinDrawModal] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);

  return (
    <Fragment>
      <div>
        {isSingle ? (
          <Fragment>
            <MynyfyText title={item.name} pBig bold style={{ padding: '5px 10px 10px' }} />
            {item?.brand ? (
              <div
                className='row brandInfo link'
                onClick={() => navigate('/Brands/' + item.brand?.urlId)}>
                {item?.brand?.logo ? <img src={item.brand?.logo?.url} className='logo' /> : null}
                <MynyfyText title={item?.brand?.brand} style={{ margin: '0px 10px' }} />
                <MynyfyText title={'View Page'} primary small className='brandBtn' />
              </div>
            ) : null}
          </Fragment>
        ) : null}
        <div className='rowSB footer'>
          {isHistory ? (
            <Fragment>
              <MynyfyText title={'View Winners'} bold link onClick={() => onDetailsClick(item)} />
              <div className='row'>
                <GroupsRounded className='groupIcon' />
                <MynyfyText title={item.totalContestants || 0} bold primary />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {/* <ScanAndWinIcon
                fill={theme.PRIMARY_BLUE}
                width={17}
                height={17}
                onClick={() => navigate('/ScanToJoin/QrScanner')}
              /> */}
              <MynyfyText title={'Details'} bold link onClick={() => onDetailsClick(item)} />
              {item.type == ContestType.SHOP_AND_SCAN ? (
                <div
                  onClick={() => {
                    if (isLoggedIn) {
                      setIsJoinDrawModal(item.isContested ? false : true);
                    } else {
                      setOpenSignIn(true);
                    }
                  }}
                  style={{ opacity: item.isContested ? 0.35 : 1 }}>
                  <MynyfyText
                    title={item.isContested ? 'Joined' : 'Enter Code'}
                    bold
                    pSmall
                    className='joinContestBtn'
                    contested={`${item.isContested}`}
                  />
                </div>
              ) : item.isContested ? (
                <MynyfyText title={'Joined'} bold link onClick={() => onDetailsClick(item)} />
              ) : null}
            </Fragment>
          )}
        </div>
      </div>

      <JoinScanAndWinByVoucher
        isJoinDrawModal={isJoinDrawModal}
        close={() => setIsJoinDrawModal(false)}
      />
      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </Fragment>
  );
};

export default ScanAndWinFooter;
