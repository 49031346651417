import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import contestService from '../../services/contestService';
import { LuckyDrawStatus } from '../../utils/enums';
import theme from '../../utils/theme';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfySpan from '../../components/MynyfySpan';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  AccessTime,
  ChevronRight,
  CircleRounded,
  HighlightOff,
  Restore,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { Card } from '@mui/material';
import MynyfyPre from '../../components/MynyfyPre';
import { useDispatch } from 'react-redux';
import './publishAd.scss';
import MynyfyHeader from '../../components/MynyfyHeader';
import { Visibility, AdsClick, PersonSearch } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import MynyfyPDFGenerator from '../../components/MynyfyPDFGenerator';
import RegularAdInvoice from '../../Invoices/RegularAdInvoice';
import moment from 'moment';
import _ from 'lodash';

const PublishAdDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();

  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (urlParams?.id) getDetailsHandler(urlParams?.id);
  }, []);

  const getDetailsHandler = (id) => {
    contestService.userAdById(id).then((res) => {
      if (res.success) {
        let adData = _.cloneDeep(res.data);
        if (adData.status == LuckyDrawStatus.ACTIVE) {
          if (adData.isDelete) adData.status = 'DELETED';
          else if (adData.endDate) {
            let days = expiryHandler(adData.endDate);
            if (days <= 0) adData.status = 'EXPIRED';
          }
        }
        setDetails(adData);
      } else {
        dispatch(snackbarInfo({ open: true, type: 'error', message: res.message }));
      }
    });
  };

  const expiryHandler = (date) => {
    let presentDate = moment().format('DD/MM/YYYY');
    let endDate = moment(date).format('DD/MM/YYYY');
    let pDate = moment(presentDate, 'DD/MM/YYYY');
    let eDate = moment(endDate, 'DD/MM/YYYY');
    let result = eDate.diff(pDate, 'days', false);
    return result + 1;
  };

  const statusColorHandler = (status) => {
    let color = {};
    if (status == LuckyDrawStatus.PENDING) {
      color = '#ffc107';
    } else if (status == LuckyDrawStatus.NOT_APPROVED) {
      color = '#ff9800';
    } else if (status == LuckyDrawStatus.REJECTED || status == 'DELETED' || status == 'EXPIRED') {
      color = 'red';
    } else if (status == LuckyDrawStatus.COMPLETED) {
      color = theme.PRIMARY_BLUE;
    } else {
      color = 'green';
    }
    return color;
  };

  return details ? (
    <div id='PublishAd'>
      <MynyfyHeader
        title={details.name}
        customElememt={() =>
          [
            LuckyDrawStatus.NOT_APPROVED,
            LuckyDrawStatus.PENDING,
            LuckyDrawStatus.REJECTED,
          ].includes(details.status) ? null : (
            <MynyfyPDFGenerator
              customBtn={() => <MynyfyText title={'Download Invoice'} link />}
              htmlString={RegularAdInvoice({ details })}
              pdfName={(details.contestId || '') + ' - Regular Ad - Invoice'}
            />
          )
        }
      />

      <div className='detailsContainer'>
        <div style={{ textAlign: 'center', marginTop: 10 }}>
          <Card style={{ borderRadius: 10 }}>
            <div>
              <img src={details.image} alt={details.name} className='drawImg' />
            </div>
            <div className='rowSB' style={{ padding: 15 }}>
              <div>
                <MynyfyText title={moment(details?.createdAt).format('DD MMM YYYY')} />
              </div>
              <div className='details'>
                <MynyfySpan title={'Status:'} primary />
                &nbsp;
                <MynyfySpan
                  title={
                    details?.status == LuckyDrawStatus.NOT_APPROVED
                      ? 'Not Approved'
                      : details?.status?.toLowerCase()
                  }
                  color={statusColorHandler(details.status)}
                  style={{ textTransform: 'capitalize' }}
                />
              </div>
            </div>
          </Card>
        </div>
        <Card className='rowC tabConatiner' style={{ padding: 15, gap: 25 }}>
          <div className='row'>
            <Tooltip title='Total Views' arrow placement='top'>
              <Visibility color='primary' />
            </Tooltip>
            <MynyfyText title={details?.totalViews || 0} style={{ paddingLeft: 5 }} />
          </div>
          <div className='row'>
            <Tooltip title='Unique Views' arrow placement='top'>
              <PersonSearch color='primary' />
            </Tooltip>
            <MynyfyText title={details?.totalContestants || 0} style={{ paddingLeft: 5 }} />
          </div>
          <div className='row'>
            <Tooltip title='Website Clicks' arrow placement='top'>
              <AdsClick color='primary' />
            </Tooltip>
            <MynyfyText title={details?.websiteClicks || 0} style={{ paddingLeft: 5 }} />
          </div>
        </Card>
        <Card className='tabConatiner'>
          {['DELETED', 'EXPIRED', LuckyDrawStatus.ACTIVE, LuckyDrawStatus.PENDING].includes(
            details.status
          ) ? (
            <div>
              <div className='rowSB details'>
                <MynyfySpan title={'Title:'} primary />
                <MynyfySpan title={details.name || '---'} />
              </div>
              <div className='rowSB details'>
                <MynyfySpan title={'Category:'} primary />
                <MynyfySpan
                  title={details?.category?.map((e) => e.name).join(', ') || '---'}
                  style={{ textAlign: 'right' }}
                />
              </div>

              <div className='rowSB details'>
                <MynyfySpan title={'State:'} primary />
                <MynyfySpan
                  title={details?.state?.map((e) => e.name).join(', ') || 'All'}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <div className='rowSB details'>
                <MynyfySpan title={'City: '} primary />
                <MynyfySpan
                  title={details?.city?.map((e) => e.name).join(', ') || 'All'}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <div className='rowSB details'>
                <MynyfySpan title={'Area: '} primary />
                <MynyfySpan
                  title={details?.area?.map((e) => e.name).join(', ') || 'All'}
                  style={{ textAlign: 'right' }}
                />
              </div>
            </div>
          ) : details.status == LuckyDrawStatus.NOT_APPROVED ? (
            <div className='row'>
              <HighlightOff style={{ marginRight: 5, color: theme.ERROR }} />
              <div
                onClick={() => {
                  navigate('/PublishAds/Edit/' + details._id, { state: { details } });
                }}>
                <MynyfySpan
                  title={'Your Ad is Not Approved, Please check the reasons and make changes'}
                  pSmall
                />
                &ensp;
                <MynyfySpan title={'click to edit'} link pSmall />
              </div>
            </div>
          ) : details.status == LuckyDrawStatus.REJECTED ? (
            <div className='row' style={{ padding: '10px 0px' }}>
              <Restore style={{ marginRight: 5, color: 'green' }} />
              <MynyfyText
                title={
                  'Your Ad has been Rejected and Initiated Refund, it may take upto 5-7 working days for the payment'
                }
                pSmall
                style={{ flexWrap: 'wrap', flexShrink: 1 }}
              />
            </div>
          ) : null}
        </Card>
        {/* <div style={{ padding: '0px 10px 8px' }}>
          {details.terms.split('$').map((res, i) => (
            <div className='row' style={{ marginTop: 10 }} key={i}>
              <CircleRounded className='dotIcon' style={{ margin: '0px 5px' }} />
              <MynyfyPre title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
            </div>
          ))}
        </div> */}
      </div>
      <div style={{ margin: '5px 10px 0px', paddingBottom: 50 }}>
        {details.isInterested &&
        (details.status == LuckyDrawStatus.ACTIVE ||
          details.status == LuckyDrawStatus.PENDING ||
          details.status == LuckyDrawStatus.COMPLETED) ? (
          <Card
            className='rowSB tabConatiner cursor'
            onClick={() => {
              navigate('/PublishAds/InterestedUsers/' + details._id, {
                state: { name: details.name },
              });
            }}>
            <MynyfyText title={'Interested Users list'} p />
            <ChevronRight style={{ margin: '0px 5px', color: theme.DARK_COLOR }} />
          </Card>
        ) : null}
        {details.status == LuckyDrawStatus.PENDING ? (
          <div className='rowC statusContainer'>
            <AccessTime style={{ margin: '0px 5px', color: theme.WARNING }} />
            <MynyfyText title={'Waiting for Approval'} />
          </div>
        ) : null}
        {details.reason ? (
          <Fragment>
            <MynyfyText title={'Reasons'} bold style={{ padding: 20 }} center />
            {details.reason.split('$').map((res, i) => (
              <div className='row' style={{ marginTop: 10 }} key={i}>
                <CircleRounded className='dotIcon' style={{ margin: '0px 5px' }} />
                <MynyfyPre title={res} style={{ flexWrap: 'wrap', flexShrink: 1 }} />
              </div>
            ))}
          </Fragment>
        ) : null}
      </div>
    </div>
  ) : (
    <MynyfyLoader />
  );
};

export default PublishAdDetails;
