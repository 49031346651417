import _ from 'lodash';
import { PaymentGateways } from './enums';
import moment from 'moment';

export const objectReplace = (objects, newObj, key) => {
  return objects.map((obj) => (obj[key] === newObj[key] ? newObj : obj));
};

export const removeObject = (objects, newObj, key) => {
  return objects.filter((obj) => obj[key] !== newObj[key]);
};

export const assignObject = (src, tar) => {
  return _.assign(src, tar);
};

export const setObject = (target, path, value) => {
  _.set(target, path, value);
  return target;
};

export const mobileNumberManipulation = (mobile) => {
  mobile = mobile.toString();
  let mobileArr = mobile.split('');
  let result = mobileArr.map((res, i) => {
    return i % 2 === 0 ? res : '*';
  });
  return result;
};

export const arrayContainsElement = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.some((e) => superset.includes(e));
};

export const arrayContainsArray = (superset, subset) => {
  if (0 === subset.length) {
    return false;
  }
  return subset.every((e) => superset.includes(e));
};

export const allEqual = (arr) => arr.every((val) => val === arr[0]);

export const getGreeting = () => {
  const d = new Date();
  const time = d.getHours();

  if (time < 12) {
    return 'Good Morning!';
  } else if (time > 12 && time < 17) {
    return 'Good Afternoon!';
  } else {
    return 'Good Evening!';
  }
};

export const getOS = () => {
  const platform = navigator.platform;
  const userAgent = navigator.userAgent;
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macPlatforms.indexOf(platform) !== -1) {
    return 'mac';
  } else if (iosPlatforms.indexOf(platform) !== -1 || userAgent.match(/iPad|iPhone|iPod/)) {
    return 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'windows';
  } else if (/Android/.test(userAgent)) {
    return 'android';
  } else if (/Linux/.test(platform)) {
    return 'linux';
  }
  return 'unknown';
};

export const merchantTxnId = ({ userId, mobile, gateway }) => {
  let userIdPart = userId?.slice(-5);
  let mobilePart = mobile?.toString()?.slice(-5);
  return gateway === PaymentGateways.SAB_PAISA
    ? `MYMT${userIdPart}${mobilePart}${Date.now()}`
    : `MYMT-${userIdPart}-${mobilePart}-${Date.now()}`;
};

export const sabpaisaPaymentGateway = ({ encData, loader }) => {
  if (encData) {
    window.open(
      `${process.env.REACT_APP_PG_API_HOST}/sabpaisaPayInPaymentRequest?encStr=${encData}`
    );
  } else {
    alert('Please check the payment details');
    loader(false);
  }
};

export const storeTimingHandler = (data) => {
  if (data?.storeOpenTime && data?.storeCloseTime) {
    let openTime = moment(data?.storeOpenTime, 'h A');
    let closeTime = moment(data?.storeCloseTime, 'h A');
    let isOpened = moment().isBetween(openTime, closeTime);
    return isOpened;
  } else {
    return true;
  }
};

export const youtubeVideoId = (url) => {
  let domine = url?.includes('youtu.be');
  let a = url?.split('?');
  let b = domine ? a?.[0].split('/') : a?.[1].split('&');
  let videoId = domine ? b?.[b.length - 1] : b?.[0].split('v=')?.[1];
  return videoId;
};

export const priceClaculation = ({ price, gst }) => {
  if (gst) {
    let reqPrice = (price / (1 + Number(gst?.data) / 100)).toFixed(2);
    return reqPrice;
  } else {
    return price;
  }
};
