import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useSelector } from 'react-redux';
import ProgressBar from '../../components/ProgressBar';

const AdItemHeader = ({ currPosition, itemIndex, item }) => {
  const mynts = useSelector((state) => state.mynts);
  const [isAnimation, setIsAnimation] = useState('false');

  useEffect(() => {
    if (item?.isContested && currPosition === itemIndex) {
      setIsAnimation('true');
      setTimeout(() => setIsAnimation('false'), 2500);
    }
  }, [item?.isContested]);

  return (
    <Fragment>
      <div className='rowSB headerContainer'>
        {item.brand ? (
          <div className='brand'>
            <img
              src={item.brand.logo ? item.brand.logo.url : item.image}
              className='headerBrandLogo'
            />
            <div>
              <MynyfyText title={item?.brand?.brand || ''} small bold />
            </div>
          </div>
        ) : (
          <div className='brand' />
        )}
        <div iscontested={isAnimation} className='row myntsHolder'>
          <img src={require('../../assets/imgs/icons/coin.png')} className='myntsIcon' />
          <MynyfyText title={mynts} small bold className='myntsTxt' />
        </div>
      </div>
      {!item.isContested && <ProgressBar currPosition={currPosition} itemIndex={itemIndex} />}
    </Fragment>
  );
};

export default AdItemHeader;
