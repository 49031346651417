import _ from 'lodash';
import validate from '../../utils/validate';
import { fetchBagCalculation, fetchBagProducts } from '../../redux/actions/bag.action';
import { objectReplace, priceClaculation } from '../../utils/CommonMethods';
import { snackbarInfo } from '../../redux/actions/snackbar.action';

let VALIDATOR = {
  selectedColor: [(value) => (value ? null : 'Select product color')],
  selectedSize: [(value) => (value ? null : 'Select product size')],
};

export const addToBag = ({ productDetails, selectedSize, selectedColor, bag, dispatch }) => {
  let qty = productDetails.count ? productDetails.count - productDetails.ordersCount : 'unlimited';
  if (qty == 'unlimited' || qty > 0) {
    let obj = {};
    if (!_.isEmpty(productDetails.size)) {
      obj.selectedSize = selectedSize;
    }
    if (!_.isEmpty(productDetails.color)) {
      obj.selectedColor = selectedColor;
    }
    let errors = validate(obj, VALIDATOR);
    if (_.isEmpty(errors)) {
      let productObj = {
        productId: productDetails._id,
        count: 1,
        price: productDetails.price,
        gst: productDetails.gst?.data || 0,
        priceBeforeGst: priceClaculation({ price: productDetails.price, gst: productDetails.gst }),
        name: productDetails.name,
        brandId: productDetails.brand?._id,
        categoryId: productDetails.category._id,
        subCategoryId: productDetails.subCategory._id,
        state: productDetails.state?.map((res) => res._id),
        city: productDetails.city?.map((res) => res._id),
        area: productDetails.area?.map((res) => res._id),
        weightRange: productDetails?.weightRange,
      };
      if (selectedSize) {
        productObj.size = selectedSize;
      }
      if (selectedColor) {
        productObj.color = selectedColor;
      }
      let clonedProducts = _.cloneDeep(bag?.products);
      if (_.isEmpty(clonedProducts)) {
        dispatch(fetchBagProducts([productObj]));
        totalCalculation({ dispatch, reqObj: [productObj] });
      } else {
        let reqProduct = clonedProducts.find((e) => e.productId == productDetails._id);
        if (reqProduct) {
          if (qty == 'unlimited' || qty > reqProduct.count) {
            reqProduct.count = reqProduct.count + 1;
            let modifiedData = objectReplace(clonedProducts, reqProduct, 'productId');
            dispatch(fetchBagProducts(modifiedData));
            totalCalculation({ dispatch, reqObj: modifiedData });
          } else {
            dispatch(
              snackbarInfo({ type: 'warning', open: true, message: `Only ${qty} are available` })
            );
          }
        } else {
          clonedProducts.push(productObj);
          dispatch(fetchBagProducts(clonedProducts));
          totalCalculation({ dispatch, reqObj: clonedProducts });
        }
      }
    } else {
      let message = Object.values(errors);
      if (message.length > 1) message.unshift('Below fields are mandatory');
      dispatch(snackbarInfo({ type: 'warning', open: true, message }));
    }
  } else {
    dispatch(
      snackbarInfo({
        type: 'warning',
        open: true,
        message: qty <= 0 ? 'Currently this product is out of stock' : `Only ${qty} are available`,
      })
    );
  }
};

export const incrementHandler = ({ productDetails, bag, dispatch }) => {
  let qty = productDetails.count ? productDetails.count - productDetails.ordersCount : 'unlimited';
  let clonedProducts = _.cloneDeep(bag?.products);
  let reqProduct = clonedProducts.find((e) => e.productId == productDetails._id);
  if (reqProduct) {
    if (qty == 'unlimited' || qty > reqProduct.count) {
      reqProduct.count = reqProduct.count + 1;
      let modifiedData = objectReplace(clonedProducts, reqProduct, 'productId');
      dispatch(fetchBagProducts(modifiedData));
      totalCalculation({ dispatch, reqObj: modifiedData });
    } else {
      dispatch(snackbarInfo({ type: 'warning', open: true, message: `Only ${qty} are available` }));
    }
  }
};

export const decrementHandler = ({ productDetails, bag, dispatch }) => {
  let clonedProducts = _.cloneDeep(bag?.products);
  let reqProduct = clonedProducts.find((e) => e.productId == productDetails._id);
  if (reqProduct) {
    if (reqProduct.count > 0) {
      reqProduct.count = reqProduct.count - 1;
      if (reqProduct.count > 0) {
        let modifiedData = objectReplace(clonedProducts, reqProduct, 'productId');
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      } else {
        let modifiedData = clonedProducts.filter((e) => e.productId !== productDetails._id);
        dispatch(fetchBagProducts(modifiedData));
        totalCalculation({ dispatch, reqObj: modifiedData });
      }
    }
  }
};

export const totalCalculation = ({ dispatch, reqObj }) => {
  if (!_.isEmpty(reqObj)) {
    let qty = 0;
    let amount = 0;
    reqObj.map((res) => {
      qty = qty + res.count;
      amount = amount + res.count * res.price;
    });
    if (qty > 0) {
      dispatch(fetchBagCalculation({ qty, amount }));
    } else {
      dispatch(fetchBagCalculation(null));
    }
  } else {
    dispatch(fetchBagCalculation(null));
  }
};

export const productFinalPriceHandler = ({ price, discount, discountType, gst, count }) => {
  let discountValue =
    discountType == 'percentage' ? (price * Number(discount)) / 100 : discount / count;
  let reqDiscountValue = Number(discountValue.toFixed(2));

  let priceWithGst = Number((price - reqDiscountValue).toFixed(2));
  let priceWithoutGst = Number((priceWithGst / (1 + gst)).toFixed(2));
  return { priceWithGst, priceWithoutGst };
};
