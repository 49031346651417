import React, { Fragment, useState } from 'react';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyText from '../../components/MynyfyText';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card } from '@mui/material';
import _ from 'lodash';
import storeService from '../../services/storeService';
import MynyfyLoader from '../../components/MynfyfLoader';
import { KeyboardDoubleArrowRight, AddCircle } from '@mui/icons-material';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { MyLocationRounded } from '@mui/icons-material';
import MynyfyPre from '../../components/MynyfyPre';
import theme from '../../utils/theme';
import moment from 'moment';
import './home.scss';
import LocationModal from '../Location/LocationModal';

const imgSize = 27;

const FoodLayout = () => {
  const navigate = useNavigate();

  const offlineFoodStores = useSelector((state) => state.stores?.offlineFoodStores);
  const onlineFoodStores = useSelector((state) => state.stores?.onlineFoodStores);
  const coupons = useSelector((state) => state.promos?.foodCoupons?.sorted);
  const shopAndWin = useSelector((state) => state.contest?.foodShopAndWin);
  const location = useSelector((state) => state.location?.location);
  const user = useSelector((state) => state.user);
  const isLoggedIn = user?.isLoggedIn;

  const [isLocationModal, setIsLocationModal] = useState(false);

  const navHandler = (screenName) => {
    navigate(screenName, { state: { isRestaurant: true } });
  };

  return (
    <Fragment>
      <MynyfyHeader
        title={'EXPLORE FOOD'}
        customElememt={() => (
          <div
            className='row cursor'
            onClick={() => window.open(process.env.REACT_APP_SELLER_WEB_HOST)}>
            <MynyfyText title={'Add Yours'} style={{ paddingRight: 5 }} />
            <AddCircle color='primary' fontSize='small' />
          </div>
        )}
      />
      <div id='Home'>
        <div onClick={() => setIsLocationModal(true)} className='cursor'>
          <div className='row' style={{ justifyContent: 'end', marginTop: 15 }}>
            <MyLocationRounded fontSize='small' color='primary' />
            <div className='row' style={{ marginLeft: 10 }}>
              <MynyfyText title={'Current Location:'} color={theme.MUTED_BLUE} pBig />
              <MynyfyText
                title={location?.area?.name}
                color={theme.NAVY_BLUE}
                pBig
                style={{ marginLeft: 10, marginRight: 20 }}
              />
            </div>
          </div>
        </div>
        <div className='divider' />
        <div style={{ paddingBottom: 20 }}>
          {_.isEmpty(coupons) ? null : (
            <div style={{ margin: 20 }}>
              <Card className='feature'>
                <div>
                  <div className='rowSB' onClick={() => navHandler('/Coupons')}>
                    <MynyfyText title={'Coupons'} bold link />
                    <MynyfyText title={'View All >>'} bold link />
                  </div>
                  <div className='offer'>
                    {coupons?.slice(0, 4)?.map((couponsDetails, i) => (
                      <div
                        onClick={() => {
                          if (isLoggedIn) {
                            navigate(`/Coupons/${couponsDetails._id}`, {
                              state: { details: couponsDetails, isRestaurant: true },
                            });
                          } else navigate('/SignIn');
                        }}
                        key={i}>
                        <Card style={{ width: 150, marginRight: 10 }} className='cursor'>
                          {couponsDetails.image ? (
                            <img
                              src={couponsDetails.image?.url}
                              alt={couponsDetails.name}
                              style={{
                                objectFit: 'cover',
                                display: 'block',
                                height: 150,
                                width: 150,
                              }}
                            />
                          ) : (
                            <div style={{ height: 150, width: 150 }}>
                              <div
                                style={{
                                  padding: 10,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  height: '-webkit-fill-available',
                                }}>
                                <MynyfyPre
                                  title={
                                    'Get \n' + theme.RUPEE + couponsDetails.actualPrice + ' off'
                                  }
                                  bold
                                  h3
                                />
                                {couponsDetails.minimumOrderValue ? (
                                  <MynyfyText
                                    title={
                                      'on a min order of ' +
                                      theme.RUPEE +
                                      couponsDetails.minimumOrderValue
                                    }
                                    style={{ paddingTop: 8 }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          )}

                          <MynyfyText
                            title={couponsDetails.name}
                            style={{ padding: 10, textTransform: 'capitalize' }}
                            className='singleLine'
                            center
                          />

                          <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                            <MynyfyPre title={`Save\nRs ${couponsDetails.actualPrice}`} small />
                            <div style={{ textAlign: 'end' }}>
                              <MynyfyPre title={`${couponsDetails.days} days`} small />
                              <MynyfyPre title={'Left'} small />
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))}
                    <KeyboardDoubleArrowRight
                      onClick={() => navHandler('/Coupons')}
                      className='all cursor'
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}

          {_.isEmpty(shopAndWin) ? null : (
            <div style={{ margin: 20 }}>
              <Card className='feature'>
                <div>
                  <div className='rowSB' onClick={() => navHandler('/ShopToJoin')}>
                    <MynyfyText title={'Giveaway Contests'} bold link />
                    <MynyfyText title={'View All >>'} bold link />
                  </div>
                  <div className='offer'>
                    {shopAndWin?.slice(0, 4)?.map((shopAndWinDetails, i) => (
                      <div
                        onClick={() => {
                          if (isLoggedIn) {
                            navigate(`/Contest/${shopAndWinDetails._id}`, {
                              state: { details: shopAndWinDetails, isRestaurant: true },
                            });
                          } else navigate('/SignIn');
                        }}
                        key={i}>
                        <Card className='cursor homeContestCard'>
                          <img
                            src={shopAndWinDetails.image}
                            alt={shopAndWinDetails.name}
                            className='contestImage'
                          />

                          <MynyfyText
                            title={shopAndWinDetails.name}
                            style={{ padding: 10, textTransform: 'capitalize' }}
                            className='singleLine'
                            center
                          />

                          <div className='rowSB' style={{ padding: '0px 10px 10px' }}>
                            <div>
                              <MynyfyText
                                title={
                                  'Ends on ' +
                                  moment(shopAndWinDetails.endDate).format('DD MMM YYYY')
                                }
                                small
                              />
                            </div>
                            <div>
                              <MynyfyPre title={'Shop to Join '} bold center small />
                            </div>
                          </div>
                        </Card>
                      </div>
                    ))}
                    <KeyboardDoubleArrowRight
                      onClick={() => navHandler('/ShopToJoin')}
                      className='all cursor'
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}

          <div className='row' style={{ margin: '0px 15px', paddingBottom: 10 }}>
            <Card
              style={{ padding: 30, flex: 1, margin: 5, borderRadius: 20 }}
              className='cursor'
              onClick={() => {
                if (location) navigate('/HomeDeliveryRestaurants');
                else setIsLocationModal(true);
              }}>
              <div className='rowC'>
                <MynyfyText title={'Home Delivery'} pBig bold style={{ textWrap: 'noWrap' }} />
                <div style={{ margin: 15 }}>
                  <img
                    src={require('../../assets/imgs/LandingPage/doorDelivery.png')}
                    width={imgSize}
                    height={imgSize}
                  />
                </div>
              </div>
            </Card>
            <Card
              style={{ padding: 30, flex: 1, margin: 5, borderRadius: 20 }}
              className='cursor'
              onClick={() => {
                if (location) navigate('/TakeAwayRestaurants');
                else setIsLocationModal(true);
              }}>
              <div className='rowC'>
                <MynyfyText title={'Take Away'} pBig bold style={{ textWrap: 'noWrap' }} />
                <div style={{ margin: 15 }}>
                  <img
                    src={require('../../assets/imgs/LandingPage/Localsellers.png')}
                    width={imgSize}
                    height={imgSize}
                    fill={theme.PRIMARY_BLUE}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <LocationModal
        isOpen={isLocationModal}
        close={(e) => {
          if (!_.isEmpty(location) || e) setIsLocationModal(false);
        }}
        closeBtn={() => setIsLocationModal(false)}
      />
    </Fragment>
  );
};

export default FoodLayout;
