import React, { Fragment, useState } from 'react';
import '../contest.scss';
import MynyfyText from '../../../components/MynyfyText';
import JoinJackpot from './JoinJackpot';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GroupsRounded } from '@mui/icons-material';
import SignInModal from '../../Auth/SignInModal';

const Jackpot = ({ item, isSingle, isHistory, isRefer, onDetailsClick }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const id = user.user?.id || null;
  const isLoggedIn = user?.isLoggedIn;

  const [isJoinDrawModal, setIsJoinDrawModal] = useState(false);
  const [joinDrawObj, setJoinDrawObj] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);

  const closeJoinDrawModal = () => {
    setIsJoinDrawModal(false);
    setJoinDrawObj(null);
  };

  return (
    <Fragment>
      <div>
        {isSingle ? (
          <Fragment>
            <MynyfyText title={item.name} pBig bold style={{ padding: '5px 10px' }} />
            {item?.brand ? (
              <div
                className='row brandInfo link'
                onClick={() => navigate('/Brands/' + item.brand?.urlId)}>
                {item?.brand?.logo ? <img src={item.brand?.logo?.url} className='logo' /> : null}
                <MynyfyText title={item?.brand?.brand} style={{ margin: '0px 10px' }} />
                <MynyfyText title={'View Page'} primary small className='brandBtn' />
              </div>
            ) : null}
          </Fragment>
        ) : null}
        <div className='rowSB footer'>
          {isHistory ? (
            <Fragment>
              {/* <div>
                <div className='row' style={{ display: 'flex', paddingTop: 5 }}>
                  <MynyfyText title={'Contest Date:'} pSmall />
                  &nbsp;
                  <MynyfyText title={moment(item.createdAt).format('DD MMM YYYY')} />
                </div>
                <div className='row' style={{ display: 'flex', paddingTop: 5 }}>
                  <MynyfyText title={'Announced Date:'} pSmall />
                  &nbsp;
                  <MynyfyText title={moment(item.endDate).format('DD MMM YYYY')} />
                </div>
              </div> */}
              <MynyfyText title={'View Winners'} bold link onClick={() => onDetailsClick(item)} />
              <div className='row'>
                <GroupsRounded className='groupIcon' />
                <MynyfyText title={item.totalContestants || 0} bold primary />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                onClick={() => {
                  if (isLoggedIn) {
                    if (!item.isContested) {
                      setIsJoinDrawModal(true);
                      setJoinDrawObj(item);
                    }
                  } else {
                    setOpenSignIn(true);
                  }
                }}
                style={{ opacity: item.isContested ? 0.35 : 1 }}>
                <MynyfyText
                  title={item.isContested ? 'Joined' : 'Join Contest'}
                  bold
                  pSmall
                  className='joinContestBtn'
                  contested={`${item.isContested}`}
                />
              </div>
              <MynyfyText title={'Details'} bold link onClick={() => onDetailsClick(item)} />
            </Fragment>
          )}
        </div>
      </div>

      <JoinJackpot
        isJoinDrawModal={isJoinDrawModal}
        joinDrawObj={joinDrawObj}
        close={closeJoinDrawModal}
        errMsg={(e) => isRefer(e)}
      />
      <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
    </Fragment>
  );
};

export default Jackpot;
