import React, { Fragment, useEffect, useState } from 'react';
import MynyfyText from '../../components/MynyfyText';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyHeader from '../../components/MynyfyHeader';
import './brands.scss';
import { CircularProgress } from '@mui/material';
import { FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { likeBrand, unlikeBrand } from './BrandsHelper';
import { userProfile } from '../../redux/actions/user.action';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import {
  fetchActualBrands,
  fetchActualFavBrands,
  fetchBrands,
  fetchFavBrands,
} from '../../redux/actions/brand.action';
import MynyfyTab from '../../components/MynyfyTab';
import AboutBrand from './AboutBrand';
import AllContests from '../Contest/AllContests';
import StoresList from '../Stores/StoresList';
import brandService from '../../services/brandService';
import Products from '../Products';
import Promos from '../Promos';
import { PromoType } from '../../utils/enums';
import { fetchPromos, fetchSortedPromos } from '../../redux/actions/promo.action';
import SignInModal from '../Auth/SignInModal';
import promoService from '../../services/promoService';
import {
  fetchBagAbout,
  fetchBagCalculation,
  fetchBagOffers,
  fetchBagProducts,
  fetchBagSellingId,
} from '../../redux/actions/bag.action';
import ProductCartHelper from '../Products/ProductCartHelper';

const BrandDetails = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const urlParams = useParams();

  const profile = useSelector((state) => state.user?.profile);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const brands = useSelector((state) => state.brands);
  const promos = useSelector((state) => state.promos);
  const bag = useSelector((state) => state.bag);

  const [brandName, setBrandName] = useState('');
  const [details, setDetails] = useState(null);
  const [isLiked, setLiked] = useState(null);
  const [openSignIn, setOpenSignIn] = useState(false);

  useEffect(() => {
    let urlId = urlParams?.brand;
    setBrandName(urlId || '');
    if (state?.brandDetails) {
      setDetails(state?.brandDetails);
    } else if (urlParams?.brand) {
      brandService.getBrandById({ urlId: urlId }).then((res) => {
        if (res.success) {
          setDetails(res.data);
        } else {
          setDetails([]);
        }
      });
    } else {
      setDetails([]);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      if (bag?.id === details._id) {
        // do nothing
      } else {
        dispatch(fetchBagSellingId(details._id));
        dispatch(
          fetchBagAbout({
            type: 'Store',
            name: details.brand,
            url: process.env.REACT_APP_WEB_HOST + '/Brands/' + details.urlId,
          })
        );
        getBuyerPromos(details._id);
        dispatch(fetchBagCalculation(null));
        dispatch(fetchBagProducts(null));
      }
    }
  }, [details]);

  const getBuyerPromos = (id) => {
    let body = { brand: id };
    promoService.getBuyerClaimedPromos(body).then((res) => {
      if (res.success) {
        dispatch(fetchBagOffers(res.data));
      }
    });
  };

  const likeHandler = (brandId, key) => {
    if (isLoggedIn) {
      let options = {
        brandId,
        setLiked,
        profile,
        data: state?.isFav ? brands?.favBrands : brands?.brands,
        actalData: state?.isFav ? brands?.actualFavBrands : brands?.actualBrands,
        dispatch,
        action: state?.isFav ? fetchFavBrands : fetchBrands,
        actualAction: state?.isFav ? fetchActualFavBrands : fetchActualBrands,
        userProfile,
        snackbarInfo,
      };
      if (key === 'like') {
        likeBrand(options);
      } else {
        unlikeBrand(options);
      }
    } else {
      setOpenSignIn(true);
    }
  };

  return details ? (
    _.isEmpty(details) ? (
      <Fragment>
        <MynyfyHeader title={brandName || 'Brand Page'} />
        <MynyfyEmptyMsg message={['We are unable to fetch the', brandName]} bold={[1]} />
      </Fragment>
    ) : (
      <div id='Brands'>
        <MynyfyHeader
          title={details?.brand}
          customElememt={() => <ProductCartHelper navFrom='brand' brandDetails={details} />}
        />
        <div className='container'>
          <div style={{ position: 'relative' }}>
            {details.banner?.[0]?.url ? (
              <img src={details.banner?.[0].url} className='banner' />
            ) : (
              <div className='banner noBanner'>
                <MynyfyText title={details.brand} italic h2 className='noBannerText' />
              </div>
            )}
            <div className='logoContainer'>
              <img src={details.logo?.url} className='logo' />
            </div>
          </div>

          <div style={{ margin: '0px 20px 20px' }}>
            <MynyfyText title={details?.brand} className='brandName' bold h5 />
            <div className='rowSB' style={{ marginTop: 10 }}>
              <div className='row'>
                <MynyfyText title={details.likesCount} bold pBig />
                &nbsp;
                <MynyfyText title={details.likesCount == 1 ? 'follower' : 'followers'} />
              </div>
              <div className='row'>
                {isLiked ? (
                  <CircularProgress size={16} />
                ) : profile?.likedBrands?.includes(details._id) ? (
                  <div className='row' onClick={() => likeHandler(details._id, 'unlike')}>
                    <FavoriteRounded fontSize='small' className='followBtn' />
                    <MynyfyText title={'Following'} />
                  </div>
                ) : (
                  <div className='row' onClick={() => likeHandler(details._id, 'like')}>
                    <FavoriteBorderRounded fontSize='small' className='followBtn' />
                    <MynyfyText title={'Follow'} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <MynyfyTab
            isScroll
            noBackBtn
            tabs={['Stores', 'Contests', 'Coupons']}
            tabComponent={[
              // <Products from='brand' brandDetails={details} />,
              // <AboutBrand details={details} />,
              <StoresList
                isFav={false}
                emptyListMsg={'No Stores available currently in your area'}
                brandDetails={details}
              />,
              <AllContests brandDetails={details} from={'brand'} />,
              // <Promos
              //   type={PromoType.REWARD}
              //   isFilter={true}
              //   emptyListTitle={'Rewards'}
              //   promos={promos?.promos?.actual}
              //   sortedPromos={promos?.promos?.sorted}
              //   action={fetchPromos}
              //   sortedAction={fetchSortedPromos}
              //   brandDetails={details}
              //   screen={'Rewards'}
              //   from={'brand'}
              // />,
              <Promos
                type={[PromoType.COUPON, PromoType.POINT_COUPON]}
                isFilter={false}
                emptyListTitle={'Coupons'}
                emptyListMsg={
                  isLoggedIn
                    ? [
                        "You don't have any Coupons",
                        'Get Coupons by participating',
                        'in Contests & Jackpots',
                      ]
                    : 'Please Login for Coupons'
                }
                emptyListMsgBold={isLoggedIn ? [1, 2] : null}
                promos={promos?.promos?.actual}
                sortedPromos={promos?.promos?.sorted}
                action={fetchPromos}
                sortedAction={fetchSortedPromos}
                brandDetails={details}
                screen={'Coupons'}
                from={'brand'}
              />,
            ]}
          />
        </div>
        <SignInModal open={openSignIn} close={() => setOpenSignIn(false)} />
      </div>
    )
  ) : (
    <MynyfyLoader />
  );
};

export default BrandDetails;
