import _ from 'lodash';
import React from 'react';
import './contest.scss';
import { useDispatch, useSelector } from 'react-redux';
import contestService from '../../services/contestService';
import { objectReplace } from '../../utils/CommonMethods';
import { ContestType } from '../../utils/enums';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import JackpotVideo from './Jackpot/JackpotVideo';

const ContestTab = ({
  data,
  action,
  onClick,
  isLoading,
  refreshing,
  onRefresh,
  isHistory,
  customComponent,
  emptyListMsg,
  emptyListTitle,
  customFooter,
}) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location?.location);

  const viewContestHandler = (id) => {
    let data = {
      state: location?.state?._id,
      city: location?.city?._id,
      area: location?.area?._id,
    };

    contestService
      .viewContest(id, data)
      .then((res) => {
        if (res.success) {
          let reqObj = data.find((e) => e._id == id);
          if (reqObj) {
            reqObj.totalViews = reqObj.totalViews ? reqObj.totalViews + 1 : 1;
            let newData = objectReplace(data, reqObj, '_id');
            dispatch(action(newData));
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const detailsNavHandler = (item) => {
    onClick(item);
    if (item.type === ContestType.SEE_AND_WIN) {
      viewContestHandler(item._id);
    }
  };

  return isLoading ? (
    <MynyfyLoader />
  ) : _.isEmpty(data) ? (
    <MynyfyEmptyMsg
      message={
        emptyListMsg
          ? emptyListMsg
          : isHistory
          ? `We haven't run any Contests in your area yet`
          : `No ${emptyListTitle} currently running in your area`
      }
    />
  ) : (
    data?.map((e, i) => (
      <div key={i} className='container' onClick={() => detailsNavHandler(e)}>
        {e.video && !isHistory ? (
          <div style={{ padding: 5 }}>
            <div style={{ borderRadius: 10, overflow: 'hidden' }}>
              <JackpotVideo
                contestDetails={e}
                className='imgW'
                playBtnSize={50}
                style={{ padding: 0 }}
              />
            </div>
          </div>
        ) : (
          <div>
            <img src={e.image} className='imgW' onClick={() => detailsNavHandler(e)} />
          </div>
        )}
        {customComponent ? customComponent(e) : null}
      </div>
    ))
  );
};

export default ContestTab;
