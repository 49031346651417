import React, { Fragment, useState, useEffect } from 'react';
import MynyfyText from '../../components/MynyfyText';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  AddCircleRounded,
  Image,
  Visibility,
  AdsClick,
  PersonSearch,
  AccessTime,
} from '@mui/icons-material';
import MynyfyHeader from '../../components/MynyfyHeader';
import MynyfyInput from '../../components/MynyfyInput';
import { useDispatch, useSelector } from 'react-redux';
import { OpportunityType } from '../../utils/enums';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import theme from '../../utils/theme';
import MynyfyLoader from '../../components/MynfyfLoader';
import MynyfyEmptyMsg from '../../components/MynyfyEmptyMsg';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import opportunityService from '../../services/opportunityService';

export default function OpportunitiesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.user);

  const [SearchFlashAds, setSearchFlashAds] = useState('');
  const [flashAds, setFlashAds] = useState(null);
  const [actualFlashAds, setActualFlashAds] = useState(null);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    let data = {
      type: OpportunityType.ANNOUNCEMENT,
      createdBy: user.id,
      isActive: checked,
    };

    opportunityService.getOpportunitiesForSeller(data).then((res) => {
      if (res.success) {
        let sortedData = _.orderBy(res.data, 'createdAt', 'desc');
        setFlashAds(sortedData);
        setActualFlashAds(res.data);
      } else {
        setFlashAds([]);
        setActualFlashAds([]);
        dispatch(snackbarInfo({ open: true, message: res.message, type: 'error' }));
      }
    });
  }, [checked]);

  const searchHandler = (key) => {
    setSearchFlashAds(key);
    if (key) {
      let sorted = actualFlashAds?.filter((e) =>
        e.description?.toLowerCase().includes(key?.toLowerCase())
      );
      setFlashAds(sorted);
    } else {
      setFlashAds(actualFlashAds);
    }
  };

  return (
    <Fragment>
      <MynyfyHeader
        title={'All Flash Ads List'}
        customElememt={() => (
          <AddCircleRounded
            color='primary'
            className='cursor'
            onClick={() => navigate('/FlashAds/Create')}
          />
        )}
      />
      {_.isEmpty(actualFlashAds) ? null : (
        <div className='rowSB' style={{ padding: 10 }}>
          <MynyfyInput
            type={'text'}
            placeHolder={'Search Ads'}
            value={SearchFlashAds}
            onChange={(e) => searchHandler(e.target.value)}
            fullWidth
          />

          <Switch checked={checked} onChange={() => setChecked(!checked)} />
        </div>
      )}
      {!flashAds ? (
        <MynyfyLoader />
      ) : _.isEmpty(flashAds) ? (
        <MynyfyEmptyMsg message={'No Flasd Ads Posted'} />
      ) : (
        <div>
          <Table sx={{ minWidth: 300 }} size='small'>
            <TableHead style={{ backgroundColor: theme.PRIMARY_BLUE }}>
              <TableRow>
                <TableCell align='center'>
                  <Tooltip title='Ads' arrow placement='top'>
                    <IconButton style={{ color: '#ffffff' }}>
                      <Image />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title='Total Views' arrow placement='top'>
                    <IconButton style={{ color: '#ffffff' }}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title='Unique Views' arrow placement='top'>
                    <IconButton style={{ color: '#ffffff' }}>
                      <PersonSearch />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title='Website Clicks' arrow placement='top'>
                    <IconButton style={{ color: '#ffffff' }}>
                      <AdsClick />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title='Status' arrow placement='top'>
                    <IconButton style={{ color: '#ffffff' }}>
                      <AccessTime />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flashAds.map((res, i) => (
                <TableRow
                  key={i}
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/FlashAds/' + res._id, { state: { details: res } })}>
                  <TableCell align='center'>
                    <img src={res.image?.url} style={{ width: 40, height: 40, borderRadius: 20 }} />
                  </TableCell>
                  <TableCell>
                    <MynyfyText title={res?.totalViews || 0} center />
                  </TableCell>
                  <TableCell>
                    <MynyfyText title={res?.uniqueViews || 0} center />
                  </TableCell>
                  <TableCell>
                    <MynyfyText title={res?.websiteClicks || 0} center />
                  </TableCell>
                  <TableCell>
                    <MynyfyText
                      title={res?.isActive ? 'Active' : 'Inactive'}
                      color={res?.isActive ? 'green' : 'red'}
                      center
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Fragment>
  );
}
